import { api } from 'common/store/api';
import type { AssignableUsersResponse } from 'common/types/Responses/AssignableUsers';
import type { AssignableUsersRequest } from 'common/types/Requests/AssignableUsers';
import {
  IUpdateWorkOrderRequest,
  WorkOrder,
  WorkOrderRequest,
  WorkOrderListResponse,
  WorkOrderListRequest,
} from 'common/types/WorkOrder';
import { FormInstance, UpdateFormInstanceRequest } from 'common/types/Form';
import { paginationToQueryParams } from 'utils/pagination';

const WORK_ORDER_ENDPOINT = 'admin/work-orders';
const FORMS_ENDPOINT = 'forms';

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['WorkOrder'] });

const workOrderEndpoints = apiWithTag.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    getWorkOrders: builder.query<WorkOrderListResponse, WorkOrderListRequest>({
      query: (data) => ({
        url: `${WORK_ORDER_ENDPOINT}/list${paginationToQueryParams(
          data.pagination,
        )}`,
        method: 'POST',
        body: data.filters,
      }),
      providesTags: (data) =>
        data && data.results
          ? [
              ...data.results.map(({ id }) => ({
                type: 'WorkOrder' as const,
                id,
              })),
              { type: 'WorkOrder', id: 'LIST' },
            ]
          : [{ type: 'WorkOrder', id: 'LIST' }],
    }),
    getWorkOrder: builder.query<WorkOrder, number>({
      query: (id) => ({ url: `${WORK_ORDER_ENDPOINT}/${id}` }),
    }),
    createWorkOrder: builder.mutation<WorkOrder, WorkOrderRequest>({
      query: (body) => ({
        method: 'POST',
        url: WORK_ORDER_ENDPOINT,
        body,
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: createdWorkOrder } = await queryFulfilled;
          if (createdWorkOrder.projectId) {
            dispatch(
              workOrderEndpoints.util.updateQueryData(
                'getWorkOrder',
                createdWorkOrder.projectId,
                (draft) => {
                  Object.assign(draft, {
                    subJobs: [...(draft?.subJobs || []), createdWorkOrder],
                  });
                },
              ),
            );
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to create work order', err);
        }
      },
      invalidatesTags: [{ type: 'WorkOrder', id: 'LIST' }],
    }),
    updateWorkOrder: builder.mutation<
      WorkOrder,
      { id: number; data: Partial<IUpdateWorkOrderRequest> }
    >({
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `${WORK_ORDER_ENDPOINT}/${id}`,
        body: data,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedWorkOrder } = await queryFulfilled;
          dispatch(
            workOrderEndpoints.util.updateQueryData('getWorkOrder', id, () => {
              return updatedWorkOrder;
            }),
          );
          if (updatedWorkOrder.projectId) {
            dispatch(
              workOrderEndpoints.util.updateQueryData(
                'getWorkOrder',
                updatedWorkOrder.projectId,
                (draft) => {
                  Object.assign(draft, {
                    subJobs: draft.subJobs?.map((job) =>
                      job.id === id ? updatedWorkOrder : job,
                    ),
                  });
                },
              ),
            );
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to edit work order', err);
        }
      },
      invalidatesTags: (result, error, { id }) => [
        { type: 'WorkOrder', id },
        { type: 'WorkOrder', id: 'LIST' },
      ],
    }),
    removeWorkOrder: builder.mutation({
      query: (id: number) => ({
        method: 'DELETE',
        url: `${WORK_ORDER_ENDPOINT}/${id}`,
      }),
      invalidatesTags: (result, error, id) => [
        { type: 'WorkOrder', id },
        { type: 'WorkOrder', id: 'LIST' },
      ],
    }),
    getAssignableUsers: builder.query<
      AssignableUsersResponse,
      AssignableUsersRequest
    >({
      query: (params) => ({
        method: 'post',
        url: 'admin/users/assignee-list',
        body: params,
      }),
    }),
    updateJobFormInstance: builder.mutation<
      FormInstance,
      UpdateFormInstanceRequest
    >({
      query: ({ formInstanceId, formInstance }: UpdateFormInstanceRequest) => ({
        url: `${FORMS_ENDPOINT}/data/${formInstanceId}`,
        method: 'PUT',
        body: formInstance,
      }),
      async onQueryStarted({ workOrderId }, { dispatch, queryFulfilled }) {
        const { data: updatedWorkOrderForm } = await queryFulfilled;
        dispatch(
          workOrderEndpoints.util.updateQueryData(
            'getWorkOrder',
            workOrderId,
            (draft) => {
              const filteredPrevForms = draft?.formInstances?.filter(
                (f) => f.id !== updatedWorkOrderForm.id,
              );
              const unUpdatedForm = draft?.formInstances?.find(
                (f) => f.id === updatedWorkOrderForm.id,
              );
              const forms = [
                ...filteredPrevForms,
                { ...unUpdatedForm, ...updatedWorkOrderForm },
              ];
              Object.assign(draft, { formInstances: forms });
            },
          ),
        );
      },
      invalidatesTags: (result, error, { workOrderId }) => [
        { type: 'WorkOrder', id: workOrderId },
        { type: 'WorkOrder', id: 'LIST' },
      ],
    }),
  }),
});

export const {
  useGetWorkOrdersQuery,
  useGetWorkOrderQuery,
  useLazyGetWorkOrderQuery,
  useCreateWorkOrderMutation,
  useUpdateWorkOrderMutation,
  useGetAssignableUsersQuery,
  useRemoveWorkOrderMutation,
  useUpdateJobFormInstanceMutation,
} = workOrderEndpoints;
