import { AccountRole } from 'common/types/AccountRole';
import useAccount from './useAccount';

function getRoleMap(role?: AccountRole) {
  if (!role) {
    return {
      isAdmin: false,
      isCoordinator: false,
      isVendor: false,
      isPropertyManager: false,
    };
  }
  return {
    isAdmin: [AccountRole.ADMIN].includes(role),
    isCoordinator: [AccountRole.ADMIN, AccountRole.COORDINATOR].includes(role),
    isVendor: [AccountRole.VENDOR].includes(role),
    isPropertyManager: [AccountRole.PROPERTY_MANAGER].includes(role),
  };
}

function useRoles() {
  const { isLoading, isAuthenticated, user, error } = useAccount();
  return {
    isLoading,
    isAuthenticated,
    role: user?.role,
    error,
    ...getRoleMap(user?.role),
  };
}

export default useRoles;
