import { useState } from 'react';
import Typography from '../Typography';
import {
  ConfirmButton,
  Container,
  Description,
  WarningModal,
} from './Warning.styles';

export interface WarningProps {
  // Should render modal warning
  modal?: boolean;
  // Description to show
  description?: string;
  // Should render loader inline
  inline?: boolean;
  // cover layout
  full?: boolean;
  // resolve after click
  onResolve?: () => void;
}

const WarningContent = ({
  description,
  inline,
  full,
  modal,
  onResolve,
}: WarningProps) => {
  return (
    <Container $inline={inline} $modal={modal} $full={full}>
      <Typography variant="heading3">Oops...</Typography>
      <Description modal={modal} variant="bodyLarge">
        {description ??
          'Something went wrong, please try again later or contact an admin for assistance.'}
      </Description>
      <ConfirmButton
        variant="secondary"
        onClick={() => {
          onResolve && onResolve();
        }}
      >
        Got it
      </ConfirmButton>
    </Container>
  );
};

const Warning = ({
  inline = true,
  full = false,
  description,
  modal,
  onResolve,
}: WarningProps) => {
  const [open, setOpen] = useState(true);

  const onClose = () => {
    setOpen(false);
    if (onResolve) {
      onResolve();
    } else {
      window.location.reload();
    }
  };

  if (modal) {
    return (
      <WarningModal open={open} hideCloseBtn>
        <WarningContent onResolve={onClose} description={description} modal />
      </WarningModal>
    );
  }

  return (
    <WarningContent
      onResolve={onClose}
      inline={inline}
      description={description}
      full={full}
    />
  );
};

export default Warning;
