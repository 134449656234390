// hooks
import { useEffect, useState, useCallback, useMemo } from 'react';
import usePubNubUser from '../../pubnub/usePubNubUser';

// types
import type { SignalEvent, ChannelMetadataObject, ObjectCustom } from 'pubnub';
import { ChatType } from 'common/types/Chat';

// constants
import { MESSAGE_TYPE } from '../../../components/chat/constants';
import useAccount from 'common/hooks/useAccount';

interface ListenerOptions {
  currentChannel: ChannelMetadataObject<ObjectCustom>;
  chatType: ChatType;
}

/**
 * Internal hook used by the usePubNubListener hook to handle signal events.
 * Currently supports signals for typing indicators.
 */
const usePubNubSignalEvent = ({
  currentChannel,
  chatType,
}: ListenerOptions) => {
  const { currentUserId } = useAccount();
  // state
  // get/set names of whoever is typing
  const [typingIndicatorNames, setTypingIndicatorNames] = useState<string[]>(
    [],
  );

  //hook data
  const { user: currentUser } = usePubNubUser(
    useMemo(
      () => ({
        uuid: currentUserId,
      }),
      [currentUserId],
    ),
  );

  // Functions
  // Name of typing user to display in indicator
  const handleSignal = useCallback(
    (signal: SignalEvent) => {
      if (
        signal.channel === currentChannel?.id &&
        signal.publisher !== currentUser?.id &&
        signal.message?.type
      ) {
        const message: { type: string; user: string } = { ...signal.message };
        if (message.type === MESSAGE_TYPE.TYPING_MESSAGE_ON && message?.user) {
          if (!typingIndicatorNames.includes(message?.user)) {
            setTypingIndicatorNames([...typingIndicatorNames, message?.user]);
          }
        } else if (
          message.type === MESSAGE_TYPE.TYPING_MESSAGE_OFF &&
          message?.user
        ) {
          if (typingIndicatorNames.includes(message?.user)) {
            const newArr = [...typingIndicatorNames];
            const indexToRemove = newArr.indexOf(message.user);
            newArr.splice(indexToRemove, 1);
            setTypingIndicatorNames(newArr);
          }
        }
      }
    },
    [currentChannel?.id, currentUser?.id, typingIndicatorNames],
  );

  // use effects
  useEffect(() => {
    setTypingIndicatorNames([]);
  }, [currentChannel, chatType]);

  return {
    typingIndicatorNames,
    setTypingIndicatorNames,
    handleSignal,
  };
};

export default usePubNubSignalEvent;
