// hooks
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';
import { useCallback } from 'react';

// types
import type {
  BaseObjectsEvent,
  ChannelMetadataObject,
  ObjectCustom,
  UUIDMetadataObject,
} from 'pubnub';

// constants
import { CHAT_TYPES } from 'constants/constants';

interface ListenerOptions {
  // current channels
  displayChannels: ChannelMetadataObject<ObjectCustom>[];
  // get latest membership data, including channels and unread counts
  fetchMoreMemberships: () => void;
}

/**
 * Internal hook used as an extension by the usePubNubListener hook to handle object API events.
 * These include metadata and membership updates.
 */
const usePubNubObjectEvent = ({
  displayChannels,
  fetchMoreMemberships,
}: ListenerOptions) => {
  //hook data
  const pubnub = usePubNub();

  // Functions
  // handle object API events. fetch additional channel data if membership changed.
  // update channel names if user meta data is updated.
  const handleObjects = useCallback(
    async (objectData: BaseObjectsEvent) => {
      try {
        if (
          (objectData.message?.type === 'membership' ||
            objectData.message?.type === 'channel') &&
          objectData.channel?.includes(pubnub?.getUUID())
        ) {
          const channelData = objectData.message?.data as {
            channel?: ChannelMetadataObject<ObjectCustom>;
          };
          const isJoinedChannel = displayChannels?.some(
            (channel) => channel.id === channelData.channel?.id,
          );

          if (
            (objectData.message?.event === 'set' && !isJoinedChannel) ||
            objectData.message?.event === 'delete'
          ) {
            fetchMoreMemberships();
          }
        }
        if (objectData.message?.type === 'uuid') {
          const data: { [key: string]: string } = {
            ...objectData.message?.data,
          };
          if (
            data.name &&
            objectData.channel?.startsWith(CHAT_TYPES.DIRECT) &&
            displayChannels?.some(
              (jChannel) => jChannel.id === objectData.channel,
            )
          ) {
            const channelMembers = (
              await pubnub?.objects?.getChannelMembers({
                channel: objectData.channel,
              })
            )?.data;
            const names = channelMembers
              ?.map((member) => {
                const memberData: Omit<
                  UUIDMetadataObject<ObjectCustom>,
                  'eTag' | 'updated'
                > = {
                  ...member.uuid,
                };
                return memberData.name;
              })
              .filter((member) => Boolean(member));
            if (names?.length) {
              await pubnub?.objects?.setChannelMetadata({
                channel: objectData.channel,
                data: {
                  name: names.join(', '),
                },
              });
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Error handling object event`, error.status || error);
      }
    },
    [fetchMoreMemberships, pubnub, displayChannels],
  );

  return {
    handleObjects,
  };
};

export default usePubNubObjectEvent;
