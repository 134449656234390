import type { User } from 'firebase/auth';
import type { FirebaseApp } from 'firebase/app';
import { getApp, getApps, initializeApp } from 'firebase/app';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import {
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  signInWithCustomToken,
  confirmPasswordReset,
} from 'firebase/auth';
import canUseDOM from 'common/utils/canUseDOM';

import { onMessage, getMessaging, MessagePayload } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
};

let app: FirebaseApp;
if (!getApps().length) {
  app = initializeApp(firebaseConfig);
} else {
  app = getApp();
}
export const auth = getAuth(app);
const storage = getStorage(app);

export function resetPassword(oobCode: string, newPassword: string) {
  return confirmPasswordReset(auth, oobCode, newPassword);
}

export function loginWithCustomIdToken(idToken: string) {
  return signInWithCustomToken(auth, idToken);
}

export function signOut() {
  return auth.signOut();
}

if (canUseDOM && process.env.NEXT_PUBLIC_ENABLE_FIREBASE_EMULATION === 'true') {
  const emulatorIP =
    process.env.NEXT_PUBLIC_FIREBASE_EMULATOR_IP || 'localhost';
  connectAuthEmulator(auth, `http://${emulatorIP}:4003`);
  connectStorageEmulator(storage, emulatorIP, 4002);
}

interface InitializationArgs {
  onMessage?: (payload: MessagePayload) => void;
  onAuthTokenChange: (token: string | null) => void;
}

export default function initializeFirebase(args: InitializationArgs) {
  if (canUseDOM) {
    onAuthStateChanged(auth, async (user: User | null) => {
      try {
        let authToken = null;
        if (user) {
          authToken = await user.getIdToken(true);
          args.onAuthTokenChange(authToken);
        } else {
          args.onAuthTokenChange(null);
        }
      } catch (err) {
        args.onAuthTokenChange(null);
      }
    });
    if (
      args.onMessage &&
      'Notification' in window &&
      Notification?.permission === 'granted' &&
      window.navigator &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    ) {
      const messaging = getMessaging();
      onMessage(messaging, args.onMessage);
    }
  }
}
