export enum AccountRole {
  WRENNER = 'WRENNER',
  TRADE_PARTNER = 'TRADE_PARTNER',
  COORDINATOR = 'COORDINATOR',
  ADMIN = 'ADMIN',
  PENDING_ACTIVATION = 'PENDING_ACTIVATION',
  VENDOR = 'VENDOR',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}

export type UserType = Exclude<
  AccountRole,
  'ADMIN' | 'COORDINATOR' | 'PENDING_ACTIVATION'
>;

enum ExtraAccountRoles {
  ALL = 'ALL',
}

export type AllPossibleAccountRoles = ExtraAccountRoles | AccountRole;
