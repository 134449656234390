export const BASE_URL =
  process.env.NEXT_PUBLIC_API_URL || 'http://localhost:8080';

export const BASE_WEBSITE_URL =
  process.env.NEXT_PUBLIC_WEBSITE_URL || 'http://localhost:3000';

export const PRE_REDIRECT_ROUTE_KEY = 'pre-redirect-route';

export const PRE_REDIRECT_ROUTE_ROLE_KEY = 'pre-redirect-role';

export const PRE_REDIRECTED_KEY = 'pre-redirected';

export const BACKEND_UNKNOWN_ERROR = 'An unexpected error occurred';

export const ONE_HOUR = 1000 * 60 * 60;
