import { api } from '../../store/api';

const USER_SETTINGS = 'user-settings';
const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['UserSettings'] });

export const userSettingsEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    setPushToken: builder.mutation({
      query: (token: string) => ({
        url: `${USER_SETTINGS}/tokens`,
        method: 'POST',
        body: { token },
      }),
    }),
    deletePushToken: builder.mutation({
      query: (token: string) => ({
        url: `${USER_SETTINGS}/tokens`,
        method: 'DELETE',
        body: { token },
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useSetPushTokenMutation, useDeletePushTokenMutation } =
  userSettingsEndpoints;
