type SuppressedNotification = {
  notification?: Date;
  installApp?: Date;
};

export const getSuppressedNotificationTime = (
  type: keyof SuppressedNotification,
) => {
  const storedsuppressdNotifications: SuppressedNotification = JSON.parse(
    localStorage.getItem('suppressdNotifications'),
  );
  return storedsuppressdNotifications?.[type];
};

export const setSuppressedNotificationTime = (
  type: keyof SuppressedNotification,
) => {
  const storedsuppressdNotifications: SuppressedNotification = JSON.parse(
    localStorage.getItem('suppressdNotifications'),
  );
  const suppressdNotifications = {
    ...storedsuppressdNotifications,
    [type]: new Date(),
  };
  localStorage.setItem(
    'suppressdNotifications',
    JSON.stringify(suppressdNotifications),
  );
};
