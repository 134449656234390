// hooks
import { useEffect, useState } from 'react';
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';

// types
import type {
  UUIDMetadataObject,
  ObjectCustom,
  GetUUIDMetadataParameters,
} from 'pubnub';
import { Account } from 'common/types/Account';
import { useLazyGetUserQuery } from 'common/services/endpoints/user';

interface UserReturnData {
  user: UUIDMetadataObject<ObjectCustom>;
  loadingUser: boolean;
  reFetchUser: () => void;
  additionalUserDetails: Account;
}

interface HookOptions extends GetUUIDMetadataParameters {
  fetchAdditionalData?: boolean;
}

/**
 * Hook to fetch pubnub user data. Also provides option to fetch additional data not available in pubnub
 * from firebase. pubnub data will have name, email, phone, role data (with role and phone under custom data)
 */
const usePubNubUser = (options?: HookOptions): UserReturnData => {
  const pubnub = usePubNub();
  // currently loading user
  const [loadingUser, setLoadingUser] = useState(false);
  // additional user details from firebase
  const [additionalUserDetails, setAdditionalDetails] = useState<Account>();
  // current pubnub user data
  const [user, setUser] = useState<UUIDMetadataObject<ObjectCustom>>();
  // get service data
  const [getUser, { isLoading: userLoading }] = useLazyGetUserQuery();

  // reest state
  const reFetchUser = () => {
    setLoadingUser(false);
    setUser(undefined);
    setAdditionalDetails(undefined);
  };

  // fetch user data
  useEffect(() => {
    const _options = { ...options };
    if (loadingUser || !pubnub) return;
    const getUserDetails = async () => {
      let details: Account = undefined;
      try {
        if (!_options?.uuid) return;
        details = (await getUser(_options?.uuid))?.data;
      } catch (error) {
        console.error(`Error getting user: ${_options?.uuid}: `, error); //eslint-disable-line
      }

      return details;
    };
    const fetchUser = async () => {
      try {
        // if we need to fetch additional data uuid must be given
        if (!_options?.uuid) return;
        setLoadingUser(true);
        const userResponse = await pubnub?.objects?.getUUIDMetadata(_options);
        const userData = userResponse?.data;
        if (_options?.fetchAdditionalData) {
          const details = await getUserDetails();
          if (details) {
            setAdditionalDetails(details);
          }
        }
        if (userData) {
          setUser(userData);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error getting pubnub user', error.status || error);
      } finally {
        setLoadingUser(false);
      }
    };
    fetchUser();
  }, [options, pubnub]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    user,
    loadingUser: loadingUser || userLoading,
    reFetchUser,
    additionalUserDetails,
  };
};

export default usePubNubUser;
