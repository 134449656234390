import { api } from 'common/store/api';
import type { ChannelMetadataObject, ObjectCustom } from 'pubnub';

interface CreateChatParams {
  users: string[];
  jobId?: number;
}

const CHAT_ENDPOINT = 'chat';

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['Chat'] });

const chatEndpoints = apiWithTag.injectEndpoints({
  overrideExisting: true,
  endpoints: (builder) => ({
    createChat: builder.mutation<
      ChannelMetadataObject<ObjectCustom>,
      CreateChatParams
    >({
      query: (body) => ({
        method: 'POST',
        url: CHAT_ENDPOINT,
        body,
      }),
    }),
  }),
});

export const { useCreateChatMutation } = chatEndpoints;
