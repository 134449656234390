import { Pagination } from 'common/types/Pagination';
import { WorkOrderSortOptions } from 'common/types/WorkOrder';
import type { SortingRule } from 'react-table';

export function paginationToQueryParams(paginationData?: Pagination) {
  return paginationData
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      `?${new URLSearchParams(paginationData as any).toString()} `
    : '';
}

export function sortingRulesToPaginationSort(
  args: SortingRule<WorkOrderSortOptions>[],
) {
  return (
    args
      // convert the parameter to a string
      .map((s) => {
        const pre = s.desc ? '-' : '';
        return `${pre}${s.id}`;
      })
      // join arguments by a comma
      .join(',')
  );
}
