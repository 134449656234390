import { api } from 'common/store/api';
import { Account, AccountRequest } from 'common/types/Account';
import { InvalidateToSRequest } from 'common/types/Requests/Admin';

const USER = 'admin/users';

const apiWithTag = api.enhanceEndpoints({
  addTagTypes: ['User', 'CompanyManager', 'CompanyManagers'],
});

export const userEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<Account[], void>({
      query: () => ({ url: USER }),
      transformResponse: (response: Account[]) => {
        return response?.map((user: Account) => ({
          ...user,
          name: `${user?.firstName} ${user?.lastName}`,
        }));
      },
    }),
    getUser: builder.query<Account, string>({
      query: (id) => ({ url: `${USER}/${id}` }),
    }),
    addUser: builder.mutation<Account, AccountRequest>({
      query: (body) => ({
        method: 'POST',
        url: USER,
        body,
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data: addedUser } = await queryFulfilled;
          dispatch(
            userEndpoints.util.updateQueryData(
              'getAllUsers',
              undefined,
              (draft) => {
                draft.push(addedUser);
              },
            ),
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error Creating User', err);
        }
      },
      invalidatesTags: ['CompanyManager', 'CompanyManagers'],
    }),
    editUser: builder.mutation<
      Account,
      { id: string; data: Partial<AccountRequest> }
    >({
      query: ({ id, data }) => ({
        method: 'PUT',
        url: `${USER}/${id}`,
        body: data,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: editedUser } = await queryFulfilled;
          dispatch(
            userEndpoints.util.updateQueryData('getUser', id, (draft) => {
              Object.assign(draft, editedUser);
            }),
          );
          dispatch(
            userEndpoints.util.updateQueryData(
              'getAllUsers',
              undefined,
              (draft) => {
                draft.map((user) =>
                  user.id === id ? Object.assign(user, editedUser) : user,
                );
              },
            ),
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Error Updating User', err);
        }
      },
      invalidatesTags: ['CompanyManager', 'CompanyManagers'],
    }),
    deleteUser: builder.mutation({
      query: (id: string) => ({
        method: 'DELETE',
        url: `${USER}/${id}`,
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(
          userEndpoints.util.updateQueryData(
            'getAllUsers',
            undefined,
            (draft) => draft.filter((user) => user.id !== id),
          ),
        );
      },
      invalidatesTags: ['CompanyManager', 'CompanyManagers'],
    }),
    invalidateToS: builder.mutation<void, InvalidateToSRequest>({
      query: (body) => ({
        method: 'POST',
        url: `${USER}/invalidate-tos`,
        body,
      }),
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetAllUsersQuery,
  useGetUserQuery,
  useLazyGetUserQuery,
  useAddUserMutation,
  useEditUserMutation,
  useDeleteUserMutation,
  useInvalidateToSMutation,
} = userEndpoints;
