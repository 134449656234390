import { setAuthToken } from 'common/store/slices/authSlice';
import initializeFirebase from 'common/utils/firebase';
import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';

interface Props {
  children: ReactNode;
}

export default function AuthProvider({ children }: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    initializeFirebase({
      onAuthTokenChange(authToken) {
        dispatch(setAuthToken(authToken));
      },
    });
  }, [dispatch]);
  return <>{children}</>;
}
