import authReducer from 'common/store/slices/authSlice';
import workOrderReducer from 'common/store/slices/workOrderSlice';
import notificationReducer from 'common/store/slices/notificationSlice';
import { ReactNode } from 'react';
import StoreProvider from 'common/providers/StoreProvider';

interface StoreWrapperProps {
  children: ReactNode;
}

const reducers = {
  auth: authReducer,
  workOrder: workOrderReducer,
  notifications: notificationReducer,
};

const StoreWrapper = ({ children }: StoreWrapperProps) => {
  return <StoreProvider reducers={reducers}>{children}</StoreProvider>;
};

export default StoreWrapper;
