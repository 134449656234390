import { configureStore, EnhancedStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { api } from './api';
import { baseSite } from './baseSite';
import { assets } from './assets';
import { BaseState, Reducers } from './types';

export let store: EnhancedStore<BaseState>;

export const setUpStore = ({ reducers }: { reducers: Reducers }) => {
  store = configureStore({
    reducer: {
      ...reducers,
      [api.reducerPath]: api.reducer,
      [baseSite.reducerPath]: baseSite.reducer,
      [assets.reducerPath]: assets.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        api.middleware,
        baseSite.middleware,
        assets.middleware,
      ),
  });
  setupListeners(store.dispatch);
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
