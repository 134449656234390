import { Component, ErrorInfo, ReactNode } from 'react';
import { captureException } from '@sentry/nextjs';
import Warning from 'styleguide/src/components/Warning';

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line
    console.error(error, errorInfo);
    captureException(error);
  }

  public onResolve() {
    window.location.reload();
  }

  public render() {
    if (this.state.hasError) {
      return <Warning onResolve={this.onResolve} modal />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
