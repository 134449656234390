import 'common/utils/sentry';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import AuthProvider from 'common/components/auth/AuthProvider';

// Component
import {
  LoadingWrapper,
  PubNubProviderWrapper,
  StoreWrapper,
} from 'components';
import ErrorBoundary from 'components/ErrorBoundary';

// Styles
import GlobalStyle from 'globalStyle';
import Reminders from 'components/reminders';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>Homebase | Wreno</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
      </Head>
      <StoreWrapper>
        <AuthProvider>
          <LoadingWrapper>
            <PubNubProviderWrapper>
              <ErrorBoundary>
                <Reminders />
                <Component {...pageProps} />
              </ErrorBoundary>
            </PubNubProviderWrapper>
          </LoadingWrapper>
          <GlobalStyle />
        </AuthProvider>
      </StoreWrapper>
    </>
  );
}

export default MyApp;
