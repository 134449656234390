// context
import {
  PubNubAppContext,
  PubNubAppContextType,
} from 'context/PubNubAppProvider';

// hooks
import { useContext } from 'react';

interface HookReturn extends PubNubAppContextType {
  unreadChats: boolean;
}

/**
 * Hook to return data from PubNubAppProvider
 */
const usePubNubAppData = (): HookReturn | Record<string, never> => {
  const contextData = useContext(PubNubAppContext);

  const channelMessageCounts = contextData?.channelMessageCounts;
  const unreadChats = channelMessageCounts
    ? Object.values(channelMessageCounts)?.some((count) => count > 0)
    : false;

  return contextData ? { ...contextData, unreadChats } : {};
};

export default usePubNubAppData;
