import type { Attachment } from 'styleguide/src/components/Attachments';
import type { Account } from './Account';
import type { FormInstance } from './Form';
import type { Address, Market } from './Market';
import type { Pagination } from './Pagination';
import type { TimeSlot } from './TimeSlot';
import type { SkillRequest } from './SkillTag';
import type { Service } from './Service';
import { WorkOrderStatus } from './WorkOrderStatus';

export const WORK_ORDER_PRE_COMPLETION_STATUSES = [
  WorkOrderStatus.DRAFT,
  WorkOrderStatus.UNCLAIMED,
  WorkOrderStatus.ACCEPTED,
  WorkOrderStatus.IN_PROGRESS,
  WorkOrderStatus.UNACCEPTED,
] as const;

export const WORK_ORDER_POST_COMPLETION_STATUSES = [
  WorkOrderStatus.SUBMITTED,
  WorkOrderStatus.IN_REVIEW,
  WorkOrderStatus.APPROVED,
  WorkOrderStatus.REWORK,
  WorkOrderStatus.TRIP_FEE_ONLY,
  WorkOrderStatus.PAID,
  WorkOrderStatus.REJECTED,
  WorkOrderStatus.CANT_SERVICE,
] as const;

export type WorkOrderPreCompletionStatus =
  (typeof WORK_ORDER_PRE_COMPLETION_STATUSES)[number];
export type WorkOrderPostCompletionStatus =
  (typeof WORK_ORDER_POST_COMPLETION_STATUSES)[number];

export enum WorkOrderType {
  JOB = 'JOB',
  PROJECT = 'PROJECT',
}

export enum WorkOrderPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  CRITICAL = 'CRITICAL',
}

export interface AccountInfo {
  id: string;
  firstName: string;
  lastName: string;
}
export type WorkOrderPaymentType = 'JOB' | 'HOURLY' | 'N/A';

export interface WorkOrder {
  id: number;
  title: string;
  description: string;
  salesforceId?: string;
  priority: WorkOrderPriority;
  status: WorkOrderStatus;
  paymentDetails: {
    amount: number;
    type: WorkOrderPaymentType;
  };
  market: Market;
  location: Address;
  attachments?: Attachment[];
  availableTimeSlots: TimeSlot[];
  acceptedTimeSlot?: TimeSlot;
  lastModified: string;
  tasks: string[];
  tenantInformation: string;
  formInstances: FormInstance[];
  type: WorkOrderType;
  subJobs?: WorkOrder[];
  creator: Account;
  creationDate: string;
  assignee?: Account;
  updatedAt?: string;
  projectId?: number;
  project?: WorkOrder;
  skillRequests?: SkillRequest[];
  services?: Service[];
  estimatedWorkHours?: number;
  startTime?: string;
  endTime?: string;
  expectedEndTime?: string;
  pausedAt: string;
  workHours?: number;
  createdAt: string;
}

export type WorkOrderSortOptions = {
  id?: number;
  title?: string;
  status?: WorkOrderStatus;
  locationName?: string;
  marketName?: string;
  acceptedTimeSlotStart?: string;
  acceptedTimeSlotEnd?: string;
  assigneeName?: string;
  formCount?: number;
  jobCount?: number;
  createdAt?: string;
  updatedAt?: string;
};

export type WorkOrderListRequest = {
  filters?: Partial<WorkOrderFilters>;
  pagination?: Pagination;
};

export type WorkOrderListResponse = {
  totalCount: number;
  results: WorkOrder[];
};

export interface WorkOrderFilters {
  statuses?: WorkOrderStatus[];
  title?: string;
  type?: WorkOrderType;
  creatorId?: string;
  assigneeId?: string;
  marketId?: number;
  projectId?: string;
}

export interface WorkOrderRequestAddress extends Address {
  address?: string;
}

export interface WorkOrderRequest {
  projectId?: number;
  title: string;
  salesforceId?: string;
  description: string;
  type: WorkOrderType;
  status: WorkOrderStatus;
  estimatedWorkHours?: number;
  paymentDetails?: {
    amount: number;
    type: WorkOrderPaymentType;
  };
  location?: WorkOrderRequestAddress;
  marketId: number;
  attachments?: Attachment[];
  skillRequestIds?: number[];
  tasks?: string[];
  availableTimeSlots?: TimeSlot[];
  acceptedTimeSlot?: TimeSlot;
  subJobs?: WorkOrder[];
  assigneeId?: string;
  tenantInformation?: string;
  formIds?: number[];
}

export type WorkOrderStatusType = {
  [key in WorkOrderStatus]: {
    color: string;
    label: string;
  };
};

export type IUpdateWorkOrderRequest = Partial<WorkOrderRequest>;

export interface WorkOrderWithIdRequest extends WorkOrderRequest {
  id?: number;
}
