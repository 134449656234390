import { ReactNode } from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';

// provider
import PubNubAppProvider from 'context/PubNubAppProvider';
import PubNubGlobalInstanceProvider from 'context/PubNubGlobalInstanceProvider';
import PubNubChatDataProvider from 'context/PubNubChatDataProvider';

// hooks
import usePubNubClient from 'hooks/pubnub/usePubNubClient';
import usePubNubNotifications from 'hooks/pubnub/usePubNubNotifications';
import Loader from 'styleguide/src/components/Loader';

interface PubNubProviderProps {
  children: ReactNode;
}

const PubNubNotificationWrapper = ({ children }: PubNubProviderProps) => {
  usePubNubNotifications();
  const router = useRouter();
  const currentUrl = router.asPath;
  const isChat = currentUrl.startsWith('/chat');
  return isChat ? (
    <PubNubChatDataProvider>{children}</PubNubChatDataProvider>
  ) : (
    <>{children}</>
  );
};

const PubNubProviderWrapper: NextPage<PubNubProviderProps> = ({
  children,
}: PubNubProviderProps) => {
  const { pubnub, loading } = usePubNubClient();

  return (
    <>
      {pubnub && loading ? (
        <Loader inline />
      ) : (
        <PubNubGlobalInstanceProvider client={pubnub}>
          <PubNubAppProvider>
            <PubNubNotificationWrapper>{children}</PubNubNotificationWrapper>
          </PubNubAppProvider>
        </PubNubGlobalInstanceProvider>
      )}
    </>
  );
};

export default PubNubProviderWrapper;
