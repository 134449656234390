import { store, setUpStore } from 'common/store';
import { ReactNode, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import Loader from 'styleguide/src/components/Loader';
import WrenoThemeProvider from 'common/providers/ThemeProvider';
import { Reducers } from 'common/store/types';

interface StoreProviderProps {
  children: ReactNode;
  reducers: Reducers;
}

const StoreProvider = ({ children, reducers }: StoreProviderProps) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setUpStore({
      reducers,
    });
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading || !store ? (
        <WrenoThemeProvider>
          <Loader fullscreen />
        </WrenoThemeProvider>
      ) : (
        <Provider store={store}>
          <WrenoThemeProvider>{children}</WrenoThemeProvider>
        </Provider>
      )}
    </>
  );
};

export default StoreProvider;
