// hooks
import { useEffect, useState, useCallback, useMemo } from 'react';
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';

// types
import type {
  ChannelMembershipObject,
  ObjectCustom,
  ChannelMetadataObject,
} from 'pubnub';

import usePubNubSubscribe from 'hooks/pubnub/usePubNubSubscribe';
import useAccount from 'common/hooks/useAccount';
import useRoles from 'common/hooks/useRoles';

/**
 * Internal hook used to get the membership data for joined channels. This hook is used to provide data
 * to the app level provider and the data is then availale to other components through the context.
 * This includes the current joined channels, as well as the channels unread message status.
 */
const usePubNubMembershipData = () => {
  const { currentUserId } = useAccount();
  const { isCoordinator } = useRoles();
  const pubnub = usePubNub();
  // channel data from pubnub that contains channels and custom data
  const [channelData, setChannelData] =
    useState<ChannelMembershipObject<ObjectCustom, ObjectCustom>[]>();
  // total number of channels
  const [totalCount, setTotalCount] = useState<number>();
  // next page to fetch, returned in response data
  const [next, setNext] = useState('');
  // unread message counts
  const [channelMessageCounts, setChannelMessageCounts] = useState<{
    [channel: string]: number;
  }>();
  // all data fetched
  const [allFetched, setAllFetched] = useState(false);
  // currently fetching data
  const [fetchingData, setFetchingData] = useState(false);
  const [joinedChannels, setJoinedChannels] = useState<
    ChannelMetadataObject<ObjectCustom>[]
  >([]);

  usePubNubSubscribe(
    useMemo(
      () => ({
        channels: [
          ...(joinedChannels || [])
            .map((joinedChannel) => joinedChannel.id)
            .filter((id) =>
              isCoordinator ? id?.includes(currentUserId) : Boolean(id),
            ),
          pubnub?.getUUID(),
        ],
      }),
      [pubnub, joinedChannels, isCoordinator, currentUserId],
    ),
  );

  // functions
  const clearCache = () => {
    setChannelData(undefined);
    setTotalCount(undefined);
    setNext(undefined);
    setAllFetched(false);
    setJoinedChannels(undefined);
  };

  // update the unread messages. inital state is populated with data from pubnub
  const updateUnreadMessages = useCallback(
    (channel: string, markread?: boolean) => {
      if (!channel) return;

      setChannelMessageCounts((currentState) => {
        const channelUnreadMessagesCopy = { ...currentState };

        if (!markread) {
          if (channelUnreadMessagesCopy[channel]) {
            channelUnreadMessagesCopy[channel]++;
          } else {
            channelUnreadMessagesCopy[channel] = 1;
          }
        } else {
          channelUnreadMessagesCopy[channel] = 0;
        }
        return channelUnreadMessagesCopy;
      });
    },
    [setChannelMessageCounts],
  );

  // use effects
  // fetch all user channel data. this includes the channels as well as the last read time tokens.
  useEffect(
    () => {
      const fetchData = async () => {
        try {
          if (allFetched || fetchingData || !pubnub) return;
          if (
            (totalCount && channelData?.length >= totalCount) ||
            totalCount === 0
          ) {
            setAllFetched(true);

            setJoinedChannels(
              channelData?.length
                ? channelData?.map(
                    (data) =>
                      data.channel as ChannelMetadataObject<ObjectCustom>,
                  )
                : undefined,
            );
            return;
          }
          setFetchingData(true);
          const membershipResponse = await pubnub?.objects?.getMemberships({
            include: {
              customFields: true,
              totalCount: true,
              channelFields: true,
              customChannelFields: true,
            },
            page: {
              next,
            },
            sort: { updated: 'desc' },
          });
          setTotalCount(membershipResponse?.totalCount);
          setNext(membershipResponse?.next);
          setChannelData(
            [
              ...(channelData || []),
              ...(membershipResponse?.data || []),
            ]?.filter((data) =>
              isCoordinator
                ? Boolean(data?.channel?.id)
                : data?.channel.id?.includes(currentUserId),
            ),
          );
          setFetchingData(false);
        } catch (error) {
          console.error(error.status || error); // eslint-disable-line no-console
        }
      };
      fetchData();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pubnub,
      next,
      totalCount,
      fetchingData,
      allFetched,
      currentUserId,
      isCoordinator,
    ],
  );

  useEffect(() => {
    if (!pubnub) {
      clearCache();
    }
  }, [pubnub]);

  // fetch the unread counts, using time tokens, once finished fetching all the channel data
  useEffect(() => {
    if (!allFetched || !totalCount || !channelData || !pubnub) return;

    const getUnreadMessagesCount = async () => {
      try {
        const lastMessageTokens: { [key: string]: string } = {};
        channelData.forEach((item) => {
          lastMessageTokens[item.channel?.id?.toString()] =
            item.custom?.lastReadTimetoken?.toString() || '1';
        });

        const messageCountData = await pubnub?.messageCounts({
          channels: Object.keys(lastMessageTokens),
          channelTimetokens: Object.values(lastMessageTokens),
        });

        if (messageCountData?.channels) {
          setChannelMessageCounts(messageCountData.channels);
        }
      } catch (error) {
        console.error(error.status || error); // eslint-disable-line no-console
      }
    };
    getUnreadMessagesCount();
  }, [allFetched, pubnub]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    channelMessageCounts,
    fetchMoreMemberships: clearCache,
    updateUnreadMessages,
    joinedChannels,
    membershipDataLoading: !allFetched,
  };
};

export default usePubNubMembershipData;
