import {
  createContext,
  useCallback,
  useEffect,
  useState,
  type ReactNode,
} from 'react';
import { getMessaging, getToken } from 'firebase/messaging';
import { useAppDispatch } from 'hooks/store';
import useAccount from 'common/hooks/useAccount';
import canUseDOM from 'common/utils/canUseDOM';
import type {
  Workbox,
  WorkboxLifecycleEvent,
  WorkboxLifecycleWaitingEvent,
} from 'workbox-window';
import { useSetPushTokenMutation } from 'common/services/endpoints/userSettings';
import { setPushToken } from 'common/store/slices/notificationSlice';

interface Props {
  children: ReactNode;
}

declare const window: Window &
  typeof globalThis & {
    workbox: Workbox;
  };

interface WorkBoxProviderType {
  updateAvailable: boolean;
  updatePWA: () => void;
}

export const WorkBoxProviderContext =
  createContext<WorkBoxProviderType>(undefined);

const WorkBoxProvider = ({ children }: Props) => {
  const workboxAvailable =
    canUseDOM && 'serviceWorker' in navigator && window.workbox !== undefined;
  const [setToken] = useSetPushTokenMutation();
  const { user, isAuthenticated } = useAccount();
  const dispatch = useAppDispatch();
  const [updateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    if (workboxAvailable && isAuthenticated) {
      const wb = window.workbox;

      const handleWaiting = (event: WorkboxLifecycleWaitingEvent) => {
        if (event.isUpdate) {
          setUpdateAvailable(true);
        }
      };

      wb.addEventListener('waiting', handleWaiting);

      return () => {
        wb.removeEventListener('waiting', handleWaiting);
      };
    }
  }, [workboxAvailable, isAuthenticated, updateAvailable]);

  useEffect(() => {
    const setUpPushNotifications = async () => {
      try {
        if (
          user &&
          isAuthenticated &&
          canUseDOM &&
          window.navigator &&
          'serviceWorker' in navigator &&
          'PushManager' in window &&
          'Notification' in window &&
          Notification?.permission === 'granted'
        ) {
          const registration = await window.navigator.serviceWorker.ready;
          const messaging = getMessaging();
          const token = await getToken(messaging, {
            serviceWorkerRegistration: registration,
          });
          dispatch(setPushToken(token));
          if (!user?.pushTokens?.includes(token)) {
            setToken(token).unwrap();
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'Error setting up Service Worker Push Notifications: ',
          error,
        );
      }
    };

    setUpPushNotifications();
  }, [dispatch, user, setToken, isAuthenticated]);

  const updatePWA = useCallback(() => {
    if (workboxAvailable && isAuthenticated) {
      const wb = window.workbox;
      const promptNewVersion = (event: WorkboxLifecycleEvent) => {
        if (event.isUpdate) {
          setUpdateAvailable(false);
        }
      };

      wb.addEventListener('waiting', (event) => {
        if (event.isUpdate) {
          wb.messageSkipWaiting();
          promptNewVersion(event);
        }
      });
      wb.addEventListener('controlling', promptNewVersion);

      wb.register();
      window.location.reload();
    }
  }, [isAuthenticated, workboxAvailable]);

  return (
    <WorkBoxProviderContext.Provider
      value={{
        updateAvailable,
        updatePWA,
      }}
    >
      {children}
    </WorkBoxProviderContext.Provider>
  );
};

export default WorkBoxProvider;
