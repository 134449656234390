import { useContext, useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { WorkBoxProviderContext } from 'context/WorkBoxProvider';
import useInstallPWA from 'hooks/useInstallPWA';
import useBrowserNotifications from 'hooks/useBrowserNotifications';
import ReminderPopUp from 'styleguide/src/components/ReminderPopup';
import { setSuppressedNotificationTime } from 'utils/helpers/localStorageHelpers';
import { Container } from './index.styles';

const Reminders = () => {
  const { readyToInstall, onRequestInstall } = useInstallPWA();
  const { canAskToEnableNotifications, requestNotificationPermissions } =
    useBrowserNotifications();
  const { updateAvailable, updatePWA } = useContext(WorkBoxProviderContext);

  const [showUpdateAvailableNotification, setUpdateAvailableNotification] =
    useState(false);
  const [showInstallAppNotification, setInstallAppNotification] =
    useState(false);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    setUpdateAvailableNotification(updateAvailable);
  }, [updateAvailable]);

  useEffect(() => {
    setInstallAppNotification(readyToInstall);
  }, [readyToInstall]);

  useEffect(() => {
    setShowNotification(canAskToEnableNotifications);
  }, [canAskToEnableNotifications]);

  return (
    <Container>
      <AnimatePresence initial={false}>
        {showUpdateAvailableNotification && (
          <ReminderPopUp
            key="1"
            title="Updates available"
            content="Click “Update Now” to get new version of Wreno Homebase."
            btnLabel2="Update Now"
            onSubmit={updatePWA}
            onClose={() => setUpdateAvailableNotification(false)}
          />
        )}
        {showInstallAppNotification && (
          <ReminderPopUp
            key="2"
            title="Install App now"
            content="Install the Homebase app now to their computer for an improved experience."
            btnLabel2="Install Now"
            onLater={() => setSuppressedNotificationTime('installApp')}
            onSubmit={onRequestInstall}
            onClose={() => setInstallAppNotification(false)}
          />
        )}
        {showNotification && (
          <ReminderPopUp
            key="3"
            title="Enable Notifications"
            content="Click “Enable Notifications” to receive updates when important events happen."
            btnLabel2="Enable Now"
            onLater={() => setSuppressedNotificationTime('notification')}
            onSubmit={requestNotificationPermissions}
            onClose={() => setShowNotification(false)}
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default Reminders;
