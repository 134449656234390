// hooks
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';
import { useCallback } from 'react';

// types
import { MessageType } from 'common/types/Chat';
import type { MessageActionEvent } from 'pubnub';

// constants
import { CHAT_TYPES } from 'constants/constants';

// interfaces
interface HookParams {
  // current displayed messages
  currentMessages: MessageType[];
  // update current messages
  setCurrentMessages: (message: MessageType[]) => void;
}

/**
 * Internal hook used as an extension by usePubNubListener. This hook provides functions to handle
 * pubnub message action events. Currently only read receipts are supported.
 */
const usePubNubMessageActionEvent = ({
  currentMessages,
  setCurrentMessages,
}: HookParams) => {
  const pubnub = usePubNub();

  // handle message action events. currently updates the message with read receipt time token
  // and uuid of user who sent the receipt.
  const handleMessageActionEvent = useCallback(
    (messageAction: MessageActionEvent) => {
      const messages = currentMessages ? [...currentMessages] : undefined;
      const messageToUpdate = messages?.find(
        (message) =>
          message.timetoken?.toString() ===
            messageAction.data?.messageTimetoken &&
          (messageAction.channel?.startsWith(CHAT_TYPES.DIRECT)
            ? message.uuid === pubnub?.getUUID()
            : true),
      );

      if (
        messageToUpdate &&
        messageAction.data.type === 'receipt' &&
        messageAction.data.value === 'read'
      ) {
        if (!messageToUpdate.data?.receipt?.read) {
          messageToUpdate.data = {
            ...(messageToUpdate.data || {}),
            receipt: {
              read: [
                {
                  actionTimetoken:
                    messageAction.data?.actionTimetoken?.toString(),
                  uuid: messageAction.publisher,
                },
              ],
            },
          };
        } else {
          messageToUpdate.data.receipt.read = [
            ...messageToUpdate.data.receipt.read,
            {
              actionTimetoken: messageAction.data?.actionTimetoken?.toString(),
              uuid: messageAction.publisher,
            },
          ];
        }

        setCurrentMessages(messages);
      }
    },
    [pubnub, currentMessages, setCurrentMessages],
  );

  return {
    handleMessageActionEvent,
  };
};

export default usePubNubMessageActionEvent;
