// hooks
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';
import { useEffect } from 'react';

// types
import type {
  MessageEvent,
  BaseObjectsEvent,
  FileEvent,
  SignalEvent,
  ChannelMetadataObject,
  ObjectCustom,
  MessageActionEvent,
} from 'pubnub';
import { ChatType, MessageType } from 'common/types/Chat';

// hooks
import usePubNubMessageFileEvent from './usePubNubMessageFileEvent';
import usePubNubObjectEvent from './usePubNubObjectsEvent';
import usePubNubSignalEvent from './usePubNubSignalEvent';
import usePubNubMessageActionEvent from './usePubNubMessageActionEvent';

interface ListenerOptions {
  // currently displayed channels, stores custom data for channels
  displayChannels: ChannelMetadataObject<ObjectCustom>[];
  setDisplayChannels: (channels: ChannelMetadataObject<ObjectCustom>[]) => void;
  // current selected channel
  currentChannel: ChannelMetadataObject<ObjectCustom>;
  // update channel list when channel added or removed
  fetchMoreMemberships: () => void;
  //; current chat type
  chatType: ChatType;
  // messages for current channel
  currentMessages: MessageType[];
  // update messages with new message/file from event
  setCurrentMessages: (message: MessageType[]) => void;
}

/**
 * This is an internal hook that is used as an extention of the usePubNubData hook.
 * The purpose of this hook is to take add a listener for the different pubnub event and
 * update the data passed into the hook as required.
 */
const usePubNubListener = ({
  displayChannels,
  setDisplayChannels,
  currentChannel,
  fetchMoreMemberships,
  chatType,
  currentMessages,
  setCurrentMessages,
}: ListenerOptions) => {
  //hook data
  const pubnub = usePubNub();
  // handle messages/files events
  const { newMessages, setNewMessages, handleFile, handleMessage } =
    usePubNubMessageFileEvent({
      displayChannels,
      setDisplayChannels,
    });
  // handle object api events
  const { handleObjects } = usePubNubObjectEvent({
    displayChannels,
    fetchMoreMemberships,
  });
  // handle signal events (ex: typing indicators)
  const { handleSignal, typingIndicatorNames, setTypingIndicatorNames } =
    usePubNubSignalEvent({
      currentChannel,
      chatType,
    });
  // handle message action events. (ex: read status)
  const { handleMessageActionEvent } = usePubNubMessageActionEvent({
    currentMessages,
    setCurrentMessages,
  });

  // use effects
  useEffect(() => {
    if (!pubnub) return;

    const listener = {
      // handle messages events
      message: (message: MessageEvent) => handleMessage(message),
      // handle object api events
      objects: (objectData: BaseObjectsEvent) => handleObjects(objectData),
      // handle files events
      file: (file: FileEvent) => handleFile(file),
      // handle signal events (ex: typing indicators)
      signal: (signal: SignalEvent) => handleSignal(signal),
      // handle message action events. (ex: read status)
      messageAction: (messageAction: MessageActionEvent) =>
        handleMessageActionEvent(messageAction),
    };

    try {
      pubnub?.addListener(listener);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Error adding pubnub event listeners: ',
        error.status || error,
      );
    }

    return () => {
      if (pubnub && pubnub?.removeListener) {
        pubnub?.removeListener(listener);
      }
    };
  }, [
    pubnub,
    handleMessage,
    handleFile,
    handleObjects,
    handleSignal,
    handleMessageActionEvent,
  ]);

  useEffect(() => {
    setTypingIndicatorNames([]);
  }, [currentChannel, chatType, setTypingIndicatorNames]);

  return {
    typingIndicatorNames,
    newMessages,
    setNewMessages,
  };
};

export default usePubNubListener;
