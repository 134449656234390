import type { Account } from 'common/types/Account';
import type {
  AccountLoginResponse,
  ChatTokenResponse,
} from 'common/types/Responses/Auth';
import type {
  AccountLoginRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
} from 'common/types/Requests/Auth';
import { api } from 'common/store/api';
import {
  loginWithCustomIdToken,
  resetPassword as firebaseResetPassword,
} from 'common/utils/firebase';
import { setAuthToken } from 'common/store/slices/authSlice';
import { APP_TYPE } from 'common/types/App';

const AUTH = 'auth';

const apiWithTag = api.enhanceEndpoints({ addTagTypes: ['Auth'] });

const authEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<Account, void>({
      query: () => ({ url: `${AUTH}/me` }),
      providesTags: ['Auth'],
    }),
    getChatToken: builder.query<ChatTokenResponse, void>({
      query: () => ({ url: `${AUTH}/chat-token` }),
      providesTags: ['Auth'],
    }),
    login: builder.mutation<AccountLoginResponse, AccountLoginRequest>({
      query: (body) => ({
        method: 'POST',
        url: `${AUTH}/login`,
        body: {
          ...body,
          appType: APP_TYPE.HOMEBASE,
        },
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const loginRes = await loginWithCustomIdToken(data.authToken);
          const token = await loginRes.user.getIdToken();
          dispatch(setAuthToken(token));
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to login', err);
        }
      },
      invalidatesTags: ['Auth'],
    }),
    forgotPassword: builder.mutation({
      query: (body: ForgotPasswordRequest) => ({
        url: `${AUTH}/forgot-password`,
        method: 'POST',
        body: {
          ...body,
          appType: APP_TYPE.HOMEBASE,
        },
      }),
    }),
    resetPassword: builder.mutation<null, ResetPasswordRequest>({
      queryFn: async (body) => {
        await firebaseResetPassword(body.oobCode, body.newPassword);
        return { data: null };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetChatTokenQuery,
  useLoginMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
} = authEndpoints;
