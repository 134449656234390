import canUseDOM from 'common/utils/canUseDOM';

export const sendNotification = ({
  title,
  options,
  onClick,
}: {
  title: string;
  options: NotificationOptions;
  onClick?: (e: Event) => void;
}) => {
  const defaultOptions: NotificationOptions = {
    icon: '/logo.png',
    silent: false,
  };
  try {
    if (
      canUseDOM &&
      'Notification' in window &&
      Notification?.permission === 'granted'
    ) {
      const notification = new Notification(title, {
        ...defaultOptions,
        ...options,
      });
      if (onClick) {
        notification.onclick = onClick;
      }
      return notification;
    }
  } catch (error) {
    //eslint-disable-next-line no-console
    console.error('Error sending notification: ', error);
  }
};
