import styled from 'styled-components';
import Button from '../Button';
import Modal from '../Modal';
import Typography from '../Typography';

export const Container = styled.div<{
  $inline?: boolean;
  $modal?: boolean;
  $full?: boolean;
}>`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: ${({ $modal }) => ($modal ? '0px 85px' : '1rem')};
  box-sizing: border-box;

  ${({ $inline }) =>
    $inline &&
    ` width: 100%;
      height: 100%;
    `}
  ${({ $full }) =>
    $full &&
    ` position: absolute; 
      top: 0;
      left: 0;
    `}
`;

export const Description = styled(Typography)<{ modal?: boolean }>`
  margin-top: 25px;
  margin-bottom: ${({ modal }) => (modal ? '45px' : '65px')};
  text-align: center;
`;

export const ConfirmButton = styled(Button)`
  padding: 10px 82.5px;
`;

export const WarningModal = styled(Modal)`
  width: 550px;
  height: 325px;
`;
