// hooks
import { createContext, useContext } from 'react';

// types
import type { ReactNode } from 'react';
import Pubnub from 'pubnub';

interface PubNubGlobalInstanceProviderProps {
  children: ReactNode;
  // pubnub client
  client: Pubnub;
}

interface ContextType {
  // pubnub instance
  pubnub: Pubnub;
}

// @ts-expect-error allow undefined for setup only
const PubNubInstanceContext = createContext<ContextType | undefined>();

// hook to access pubnub client
export const usePubNub = () => {
  const client = useContext(PubNubInstanceContext);
  return client?.pubnub;
};

/**
 * Provider that gives app level access to instance of pubnub
 */
const PubNubGlobalInstanceProvider = ({
  children,
  client,
}: PubNubGlobalInstanceProviderProps) => {
  return (
    <PubNubInstanceContext.Provider
      value={{
        pubnub: client,
      }}
    >
      {children}
    </PubNubInstanceContext.Provider>
  );
};

export default PubNubGlobalInstanceProvider;
