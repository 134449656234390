import type { ReactNode } from 'react';
import type { NextPage } from 'next';
import Loader from 'styleguide/src/components/Loader';

// Hooks
import useAccount from 'common/hooks/useAccount';
import WorkBoxProvider from 'context/WorkBoxProvider';

interface LoadingWrapperProps {
  children: ReactNode;
}

const LoadingWrapper: NextPage<LoadingWrapperProps> = ({
  children,
}: LoadingWrapperProps) => {
  const { isLoading, isAuthenticated } = useAccount();
  const loading = isLoading && isAuthenticated;

  return (
    <WorkBoxProvider>
      {loading && <Loader fullscreen />}
      {!loading && children}
    </WorkBoxProvider>
  );
};

export default LoadingWrapper;
