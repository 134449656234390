// hooks
import { useRouter } from 'next/router';
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';
import { useCallback, useEffect } from 'react';
import useBrowserNotifications from '../useBrowserNotifications';
import usePubNubAppData from './usePubNubAppData';

// utils
import { createChatURL } from 'utils/pubnubUtils';

// types
import type {
  FileEvent,
  MessageEvent,
  BaseObjectsEvent,
  ChannelMetadataObject,
  ObjectCustom,
} from 'pubnub';
import { PubNubFile } from 'common/types/Chat';

// constants
import { CHAT_TYPES } from 'constants/constants';
import { WorkOrder } from 'common/types/WorkOrder';
import { useLazyGetWorkOrderQuery } from 'services/endpoints/workOrder';
import useAccount from 'common/hooks/useAccount';

/**
 * Hook to send notifications for pubnub events. This includes new text based messages, file messages
 * and object API events. This hook is called at the app level and uses data from the app level context.
 */
const usePubNubNotifications = () => {
  const [getWorkOrderFn] = useLazyGetWorkOrderQuery();
  // request notification permissions for browser
  const { sendNotification } = useBrowserNotifications();
  const pubnub = usePubNub();
  const router = useRouter();
  // joined channels data from app level provider
  const { joinedChannels, fetchMoreMemberships, updateUnreadMessages } =
    usePubNubAppData();
  const { currentUserId } = useAccount();

  const getWorkOrder = useCallback(
    async (channel: string) => {
      let workOrderData: WorkOrder = null;
      try {
        const idParts = channel?.split('.');
        const jobId = channel?.startsWith(CHAT_TYPES.JOB)
          ? Number(idParts[1])
          : undefined;
        if (jobId) {
          workOrderData = await getWorkOrderFn(jobId).unwrap();
        }
      } catch (error) {
        // eslint-disable-next-line
        console.error(`Error getting workorder`, error);
      }

      return workOrderData;
    },
    [getWorkOrderFn],
  );
  // send the notification if not current active channel
  const handleNotification = useCallback(
    async ({
      content,
      image,
      channel,
      userId,
      title,
      timetoken,
      archived,
      overrideChannel,
    }: {
      content?: string;
      image?: string;
      channel: string;
      userId: string;
      title?: string;
      timetoken: string | number;
      archived?: boolean;
      overrideChannel?: boolean;
    }) => {
      try {
        if (!pubnub) return;
        const user =
          userId && pubnub
            ? (
                await pubnub?.objects?.getUUIDMetadata({
                  uuid: userId,
                })
              )?.data
            : undefined;
        const workOrder = channel.startsWith(CHAT_TYPES.JOB)
          ? await getWorkOrder(channel)
          : undefined;
        const chatUrl = createChatURL(channel, pubnub?.getUUID());
        const currentUrl = router.asPath;

        const getTitle = () => {
          if (title) {
            return title;
          } else if (user) {
            return `New Message from ${user.name}${
              workOrder ? ` for job "${workOrder.title}"` : ''
            }`;
          } else {
            return `New Notifiation${
              workOrder ? ` for job "${workOrder.title}"` : ''
            }`;
          }
        };

        const getBody = () => {
          if (content) {
            return content;
          } else if (archived) {
            return `Chat${user?.name ? ` with ${user.name}` : ''}${
              workOrder ? ` for job "${workOrder.title}"` : ' has been archived'
            }`;
          } else {
            return '';
          }
        };

        if (chatUrl !== currentUrl || overrideChannel) {
          if (
            channel.startsWith(CHAT_TYPES.JOB) ||
            channel.startsWith(CHAT_TYPES.DIRECT)
          ) {
            updateUnreadMessages(channel);
          }
          sendNotification({
            title: getTitle(),
            options: {
              body: getBody(),
              icon: image,
              tag: `${channel}-${userId}-${timetoken}`,
            },
            onClick: chatUrl
              ? () => {
                  router.push(chatUrl);
                }
              : undefined,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error sending notification', error.status || error);
      }
    },
    [pubnub, getWorkOrder, router, sendNotification, updateUnreadMessages],
  );

  // handle logic for text based messages
  const handleMessageNotification = useCallback(
    (messageEvent: MessageEvent) => {
      if (!pubnub) return;

      const { message, channel, publisher, timetoken } = messageEvent;
      try {
        if (publisher !== pubnub?.getUUID()) {
          handleNotification({
            content: message?.text || message,
            channel,
            userId: publisher,
            timetoken,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'Error handling new message API notification:',
          error.status || error,
        );
      }
    },
    [handleNotification, pubnub],
  );

  // handle logic for object API event
  const handleObjectNotification = useCallback(
    async (objectEvent: BaseObjectsEvent) => {
      const { message, channel, timetoken, publisher } = objectEvent;
      if (!pubnub) return;
      const currentUrl = router.asPath;
      const channelData = objectEvent.message
        ?.data as ChannelMetadataObject<ObjectCustom>;
      try {
        if (
          publisher !== pubnub?.getUUID() &&
          message?.type === 'channel' &&
          !currentUrl?.includes('chat')
        ) {
          fetchMoreMemberships();
        }

        if (
          message?.event === 'set' &&
          message?.type === 'channel' &&
          channelData?.custom?.archiveDate &&
          channelData?.custom?.status === 'ARCHIVED'
        ) {
          const isDirect = channelData?.id?.startsWith('direct');
          const userId = channelData?.id
            ?.split('.')
            ?.slice(isDirect ? 1 : 2)
            ?.filter((item) => item !== pubnub?.getUUID())
            ?.join();
          handleNotification({
            title: 'Chat Archived',
            channel,
            timetoken,
            userId,
            archived: true,
            overrideChannel: true,
          });
        }
        if (
          publisher !== pubnub?.getUUID() &&
          message?.type === 'membership' &&
          message?.event === 'set'
        ) {
          const channelData = objectEvent.message?.data as {
            channel?: ChannelMetadataObject<ObjectCustom>;
          };
          if (
            !joinedChannels?.some(
              (currentChannel) =>
                currentChannel?.id === channelData.channel?.id,
            )
          ) {
            if (!currentUrl?.includes('chat')) {
              fetchMoreMemberships();
            }
            if (channelData.channel?.id?.includes(currentUserId)) {
              handleNotification({
                title: 'New Chat',
                content: `You've been added to a new chat`,
                channel,
                timetoken,
                userId: publisher,
              });
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'Error handling new object API notification: ',
          error.status || error,
        );
      }
    },
    [
      handleNotification,
      pubnub,
      joinedChannels,
      fetchMoreMemberships,
      router,
      currentUserId,
    ],
  );

  // handle logic for file based messages
  const handleFileNotification = useCallback(
    async (fileEvent: FileEvent) => {
      if (!pubnub) return;

      try {
        const { file, channel, publisher, timetoken } = fileEvent;

        const pubnubFile: PubNubFile = await pubnub?.downloadFile({
          channel,
          id: file?.id,
          name: file?.name,
        });

        if (
          publisher !== pubnub?.getUUID() /*&& channel !== currentChannelId*/
        ) {
          handleNotification({
            content: file.name,
            image: pubnubFile ? URL.createObjectURL(pubnubFile?.data) : '',
            channel,
            userId: publisher,
            timetoken,
          });
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          'Error handling new file API notification:',
          error.status || error,
        );
      }
    },
    [handleNotification, pubnub],
  );

  // add listener
  useEffect(() => {
    if (!pubnub) return;

    const listener = {
      message: (messageEvent: MessageEvent) =>
        handleMessageNotification(messageEvent),
      objects: (objectEvent: BaseObjectsEvent) =>
        handleObjectNotification(objectEvent),
      file: (fileEvent: FileEvent) => handleFileNotification(fileEvent),
    };

    try {
      pubnub?.addListener(listener);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        'Error adding pubnub event listeners: ',
        error.status || error,
      );
    }

    return () => {
      if (pubnub && pubnub?.removeListener) {
        pubnub?.removeListener(listener);
      }
    };
  }, [
    pubnub,
    handleFileNotification,
    handleMessageNotification,
    handleObjectNotification,
  ]);
};

export default usePubNubNotifications;
