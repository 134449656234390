import type { UUIDMetadataObject, ObjectCustom } from 'pubnub';
import { MessageType } from 'common/types/Chat';
import PubNub from 'pubnub';
import { Account } from 'common/types/Account';
import { WorkOrder } from 'common/types/WorkOrder';
import { NotificationTag } from 'common/types/Notification';
import { CHAT_TYPES } from 'constants/constants';
import { AllPossibleAccountRoles } from 'common/types/AccountRole';

export const createChatURL = (id: string, userId: string) => {
  if (!id || !userId) return;
  const idParts = id.split('.').filter((item) => item !== userId);
  return `/chat/${idParts[0]}/${idParts[1]}${
    idParts[2] ? `-${idParts[2]}` : ''
  }`;
};

export const formatName = (
  name?: string,
  currentUser?: UUIDMetadataObject<ObjectCustom>,
) => {
  if (!name || !currentUser) return name;
  const names = name.split(',');
  if (names && currentUser) {
    names.splice(names.indexOf(currentUser.name), 1);
  }
  return names ? names.join(', ') : name;
};

export const getChatParams = (chatId: string) => {
  const [chatType, arg1, arg2, arg3] = chatId.split('.');
  return { chatType, arg1, arg2, arg3 };
};

type DirectNotificationData = {
  tag: NotificationTag;
  chatId: string;
  user?: Account & { custom?: { role: AllPossibleAccountRoles } };
};

type JobNotificationData = {
  tag: NotificationTag;
  chatId: string;
  title?: string;
};

type NotificationData = DirectNotificationData | JobNotificationData;

export const buildMessageBody = (
  message: string,
  publisherName: string | undefined,
  tokens: string[],
  chatId?: string,
  messageProp?: Partial<Account> | WorkOrder,
  chatType?: string,
) => {
  let data: NotificationData = {
    tag: NotificationTag.JOB_CHAT,
    chatId,
  };

  const title = publisherName
    ? `You have a message from ${publisherName}`
    : 'You received new message';

  const builder = PubNub.notificationPayload(title, message);
  const messagePayload = builder.buildPayload(['fcm']) as {
    text: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pn_gcm: any;
  };
  messagePayload.text = message;
  messagePayload.pn_gcm.pn_exceptions = tokens;

  if (chatType === CHAT_TYPES.DIRECT && messageProp) {
    data = {
      ...data,
      tag: NotificationTag.DIRECT_CHAT,
      user: messageProp,
    } as DirectNotificationData;
  } else if (chatType === CHAT_TYPES.JOB && messageProp) {
    data = { ...data, title: (messageProp as WorkOrder)?.title || '' };
  }

  messagePayload.pn_gcm.data = data;

  return messagePayload;
};

export const getMessageContentAndDate = (message: MessageType) => {
  if (!message) return { message: undefined, timetoken: undefined };
  const messageContent =
    typeof message?.message === 'string'
      ? message?.message
      : message?.message?.file?.name || message?.message?.text || '';
  const messageTime = message?.timetoken?.toString() || '';

  return { message: messageContent, timetoken: messageTime };
};

export function getChannelId(userIds: string[], jobId?: number): string {
  return `${jobId ? `${CHAT_TYPES.JOB}.${jobId}` : CHAT_TYPES.DIRECT}.${userIds
    .slice()
    .sort()
    .join('.')}`;
}
