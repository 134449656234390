import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import {
  type WorkOrderWithIdRequest,
  WorkOrderType,
} from 'common/types/WorkOrder';
import { WorkOrderDefault } from 'common/types/WorkOrderDefault';

export type ProjectPrePostCompleteState = 0 | 1;

export type WorkOrderState = {
  // Cache for jobs-and-projects route tab
  currentProjectAndJobsView: WorkOrderType;
  // Cache for jobs-and-projects pre/post complete tab
  currentProjectAndJobsViewCompletion: ProjectPrePostCompleteState;
  // data to pass from jobs to project when pressing "Make this a Project"
  defaultWorkOrderData: WorkOrderDefault | null;
  // data to pre-fill on job open
  jobTemplate: Partial<WorkOrderWithIdRequest> | null;
};

type RootState = {
  workOrder: WorkOrderState;
};

export const workOrderInitialState: WorkOrderState = {
  defaultWorkOrderData: null,
  currentProjectAndJobsView: WorkOrderType.JOB,
  currentProjectAndJobsViewCompletion: 0,
  jobTemplate: null,
};

const workOrderSlice = createSlice({
  name: 'account',
  initialState: workOrderInitialState,
  reducers: {
    clearDefaultWorkOrder(state) {
      state.defaultWorkOrderData = null;
    },
    clearCurrentProjectAndJobsView(state) {
      state.currentProjectAndJobsView = WorkOrderType.JOB;
      state.currentProjectAndJobsViewCompletion = 0;
    },
    setCurrentProjectAndJobsView(
      state,
      { payload }: PayloadAction<WorkOrderType>,
    ) {
      state.currentProjectAndJobsView = payload;
    },
    setCurrentProjectAndJobsViewCompletion(
      state,
      { payload }: PayloadAction<ProjectPrePostCompleteState>,
    ) {
      state.currentProjectAndJobsViewCompletion = payload;
    },
    setDefaultWorkOrderData(
      state,
      { payload }: PayloadAction<WorkOrderDefault>,
    ) {
      state.defaultWorkOrderData = payload;
    },
    setJobTemplate(
      state,
      { payload }: PayloadAction<Partial<WorkOrderWithIdRequest | null>>,
    ) {
      state.jobTemplate = payload;
    },
  },
});

export const {
  clearDefaultWorkOrder,
  clearCurrentProjectAndJobsView,
  setCurrentProjectAndJobsView,
  setCurrentProjectAndJobsViewCompletion,
  setDefaultWorkOrderData,
  setJobTemplate,
} = workOrderSlice.actions;
export default workOrderSlice.reducer;
export const selectDefaultWorkOrderData = (state: RootState) =>
  state.workOrder.defaultWorkOrderData;
export const selectCurrentProjectAndJobsView = (
  state: RootState,
): WorkOrderType => state.workOrder.currentProjectAndJobsView;
export const selectCurrentProjectAndJobsViewCompletion = (
  state: RootState,
): number => state.workOrder.currentProjectAndJobsViewCompletion;
export const selectJobTemplate = (state: RootState) =>
  state.workOrder.jobTemplate;
