import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { BASE_URL } from 'common/constants';
import { auth } from '../../common/utils/firebase';
import { RootState } from './store';
import { setAuthToken } from '../store/slices/authSlice';

type ExtraOptions = {
  shouldNotRefetchOnFail?: boolean;
};

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.authToken;
    if (token) {
      headers.set('authorization', token);
    }
    return headers;
  },
});

const baseQueryWithReAuth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions: ExtraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.status === 401) {
    try {
      const { currentUser } = auth;
      if (!currentUser) {
        throw new Error('no user is logged in');
      }
      const newIdToken = await currentUser.getIdToken();
      api.dispatch(setAuthToken(newIdToken));

      if (!extraOptions?.shouldNotRefetchOnFail) {
        result = await baseQuery(args, api, extraOptions);

        if (result.error && result.error.status === 401) {
          await auth.signOut();
          api.dispatch(setAuthToken(null));
        }
      }
    } catch (err) {
      const { currentUser } = auth;
      if (currentUser) {
        await auth.signOut();
      }
      api.dispatch(setAuthToken(null));
    }
  }

  return result;
};

/**
 * Not too sure why this is needed,
 * but we found that when doing production builds using docker,
 * that the version of react-redux imported internally by
 * @reduxjs/toolkit/query/react was not correct,
 * resulting in the queries to throw an unhandled error.
 *
 * See source code here:
 * https://github.com/reduxjs/redux-toolkit/blob/3de00f4d49e0a7372605531d47bd49f808bd18c2/packages/toolkit/src/query/react/module.ts#L17
 */
const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    hooks: {
      useDispatch: useDispatch,
      useSelector: useSelector,
      useStore: useStore,
    },
  }),
);

export const api = createApi({
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  reducerPath: 'api',
});
