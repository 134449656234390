// hooks
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';
import usePubNubUser from 'hooks/pubnub/usePubNubUser';
import usePubNubListener from 'hooks/internal/pubnub/usePubNubListener';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import usePubNubChannels from './usePubNubChannels';

// types
import { ChatType, User, MessageType } from 'common/types/Chat';
import type { ChannelMetadataObject, ObjectCustom } from 'pubnub';

// constants
import { CHAT_TYPES } from 'constants/constants';

// utils
import { getChannelId, getMessageContentAndDate } from 'utils/pubnubUtils';
import useAccount from 'common/hooks/useAccount';
import useRoles from 'common/hooks/useRoles';

/**
 * Internal hook which handles data shared across different chat controllers. The hook provides the data to
 * the chat level context provider, which the controllers can use to access data. This hook uses several other
 * hooks as extension to manage chat logic.
 */
const usePubNubData = () => {
  const { currentUserId } = useAccount();
  const { isCoordinator } = useRoles();
  const router = useRouter();
  const pubnub = usePubNub();
  const { chatType: routerChatType, chatId: routerChatId } = router.query as {
    chatType: ChatType;
    chatId: string;
  };

  // state
  const [chatType, setChatType] = useState<ChatType>(
    routerChatType || CHAT_TYPES.JOB,
  );
  const [displayMessages, setDisplayMessages] = useState<
    MessageType[] | undefined
  >();
  const [updatingChannels, setUpdatingChannels] = useState(true);

  // hook data
  const { user: currentUser } = usePubNubUser(
    useMemo(
      () => ({
        uuid: currentUserId,
      }),
      [currentUserId],
    ),
  );

  const {
    displayChannels,
    setDisplayChannels,
    joinedChannels,
    fetchMoreMemberships,
    createChat,
    joinedChannelLoading,
    currentChannel,
    handleChannelUpdate,
    creatingChannel,
    setCreatingChannel,
    setCurrentChannel,
    channelMessageCounts,
    updateUnreadMessages,
    removingChannel,
    setRemovingChannel,
    handleLeaveChannel,
    handleArchiveChannel,
  } = usePubNubChannels({ chatType, setChatType });

  // variables
  const displayChannelsString = JSON.stringify(displayChannels);
  const joinedChannelsString = JSON.stringify(joinedChannels);

  // functions
  // callback to handle user search (creating direct chat or adding coordinators in job chat)
  // if channel is found set current, otherwise create new
  const handleUserSearch = useCallback(
    async ({
      user,
      userChatType,
      jobId,
    }: {
      user: User;
      userChatType: ChatType;
      jobId?: string;
    }) => {
      const foundChannel = displayChannels?.find(
        (channel) =>
          channel.id.startsWith(userChatType) &&
          channel.id.includes(user.id) &&
          (jobId ? channel.id.includes(jobId) : true),
      );

      let channel: ChannelMetadataObject<ObjectCustom>;

      if (foundChannel) {
        channel = foundChannel;
        handleChannelUpdate(foundChannel);
      } else {
        channel = await createChat({
          createChatType: userChatType,
          userId: user.id,
          jobId,
        });
      }

      return channel;
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pubnub,
      createChat,
      displayChannelsString,
      joinedChannelsString,
      currentUser?.id,
    ],
  );

  // update the timetoken for unread counts
  const updateMemberShipsTimeToken = useCallback(
    async (channel: string, timetoken?: string) => {
      try {
        if (!pubnub || !channel) return;
        await pubnub?.objects?.setMemberships({
          uuid: pubnub?.getUUID(),
          channels: [
            {
              id: channel,
              custom: {
                lastReadTimetoken: timetoken || Date.now() * 10000,
              },
            },
          ],
        });
      } catch (error) {
        console.error(error.status || error); // eslint-disable-line no-console
      }
    },
    [pubnub],
  );

  const { typingIndicatorNames, setNewMessages, newMessages } =
    usePubNubListener({
      displayChannels,
      setDisplayChannels,
      currentChannel,
      fetchMoreMemberships,
      chatType,
      currentMessages: displayMessages,
      setCurrentMessages: setDisplayMessages,
    });

  const handleHomeRedirect = useCallback(
    (channels: ChannelMetadataObject<ObjectCustom>[]) => {
      if (channels?.length) {
        let newChannel: ChannelMetadataObject<ObjectCustom>;

        if (chatType) {
          newChannel = channels.find((channel) => {
            return channel?.id?.startsWith(chatType);
          });
        }
        if (!newChannel) {
          newChannel = channels[0];
        }

        handleChannelUpdate(newChannel);
        const chatTypeToSet = newChannel?.id?.startsWith('job')
          ? CHAT_TYPES.JOB
          : CHAT_TYPES.DIRECT;
        setChatType(chatTypeToSet);
      } else {
        router.push('/chat');
      }
    },
    [router, handleChannelUpdate, chatType],
  );

  const createChatFromURL = useCallback(async () => {
    const routerChatIdParts = routerChatId?.split('-');

    const uId =
      routerChatType === CHAT_TYPES.DIRECT
        ? routerChatIdParts[0]
        : routerChatIdParts[1];
    const jId =
      routerChatType === CHAT_TYPES.JOB ? routerChatIdParts[0] : undefined;
    const channel = await handleUserSearch({
      user: {
        id: uId,
        name: undefined,
        role: undefined,
        email: undefined,
      },
      userChatType: routerChatType,
      jobId: jId,
    });
    setChatType(routerChatType);

    return channel;
  }, [handleUserSearch, routerChatId, routerChatType]);

  const setUpCurrentChat = useCallback(
    async (channels: ChannelMetadataObject<ObjectCustom>[]) => {
      if (!channels) return;
      const removingLastChannel =
        removingChannel && displayChannels?.length === 0;
      if (removingLastChannel && !currentChannel && !joinedChannelLoading) {
        handleHomeRedirect([]);
      } else if (!joinedChannelLoading) {
        const isJobChat = routerChatType && routerChatType === CHAT_TYPES.JOB;

        let findMatch =
          routerChatType &&
          routerChatId &&
          currentChannel?.id?.startsWith(routerChatType) &&
          currentChannel?.id?.includes(routerChatId)
            ? channels?.find((channel) => channel.id === currentChannel?.id)
            : undefined;

        if (!findMatch && routerChatType && routerChatId) {
          const chatID = !isJobChat
            ? getChannelId([routerChatId, currentUserId])
            : getChannelId(
                [routerChatId.split('-')[1], currentUserId],
                Number(routerChatId.split('-')[0]),
              );

          const findChannel = channels?.find(
            (channel) =>
              channel?.id?.startsWith(routerChatType) &&
              channel?.id?.includes(chatID),
          );

          if (findChannel) {
            findMatch = findChannel;
          } else if (!removingChannel && !creatingChannel) {
            findMatch = await createChatFromURL();
          }
        }
        setRemovingChannel(false);

        if (findMatch) {
          setCurrentChannel(findMatch);
        } else {
          handleHomeRedirect(displayChannels);
        }
      }
    },
    [
      createChatFromURL,
      creatingChannel,
      currentChannel,
      displayChannels,
      joinedChannelLoading,
      handleHomeRedirect,
      removingChannel,
      routerChatId,
      routerChatType,
      setCurrentChannel,
      setRemovingChannel,
      currentUserId,
    ],
  );

  // use effects

  useEffect(
    () => {
      const setUp = async () => {
        await setUpCurrentChat(displayChannels);
      };
      if (!joinedChannelLoading && !creatingChannel && !updatingChannels) {
        setUp();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      displayChannelsString,
      joinedChannelsString,
      joinedChannelLoading,
      routerChatId,
      routerChatType,
      updatingChannels,
      creatingChannel,
    ],
  );

  // update channels meta with last sent message and time token
  useEffect(
    () => {
      const checkMemberships = async () => {
        if (!pubnub || joinedChannelLoading) return;
        try {
          const response = (await pubnub?.objects?.getMemberships())?.data;
          if (!response.length) {
            setDisplayChannels([]);
            setUpdatingChannels(false);
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error.status || error);
        }
      };
      const getChannelLastMessages = async () => {
        if (!pubnub) return;
        try {
          setUpdatingChannels(true);
          const joinedChannelsIds = joinedChannels
            .map((jChannel) => jChannel.id)
            ?.filter((id) =>
              isCoordinator ? Boolean(id) : id?.includes(currentUserId),
            );
          const messageResponse = (
            await pubnub?.fetchMessages({
              channels: joinedChannelsIds,
              count: 1,
            })
          )?.channels;

          const _newChannels = joinedChannels.map((jChannel) => {
            const channelMessages = messageResponse[jChannel.id];
            const dChannel = { ...jChannel };
            const messageData = channelMessages
              ? getMessageContentAndDate(channelMessages[0])
              : undefined;
            const dChannelCustomData = {
              ...(dChannel.custom || {}),
              lastMessage: messageData?.message,
              lastMessageTime: messageData?.timetoken,
            };
            dChannel.custom = dChannelCustomData;
            return dChannel;
          });
          const newChannels = _newChannels?.filter(
            (c) => messageResponse[c.id]?.length > 0,
          );
          setDisplayChannels((currentChannels) => {
            let copyCurrentChannels: ChannelMetadataObject<ObjectCustom>[];
            if (currentChannels) {
              copyCurrentChannels = currentChannels.filter(
                (c) => !newChannels.some((newChannel) => newChannel.id == c.id),
              );
            }

            const newArray = [...(copyCurrentChannels || []), ...newChannels];

            return newArray.sort((a, b) => {
              return a.custom?.lastMessageTime?.toString() >
                b.custom?.lastMessageTime?.toString()
                ? -1
                : 1;
            });
          });
          setUpdatingChannels(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error.status || error);
        }
      };
      if (joinedChannels?.length > 0) {
        getChannelLastMessages();
      } else {
        checkMemberships();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [joinedChannelsString, pubnub],
  );

  return {
    chatType,
    setChatType,
    newMessages,
    setNewMessages,
    setCreatingChannel,
    displayChannels,
    setDisplayChannels,
    currentChannel,
    typingIndicatorNames,
    updateUnreadMessages,
    handleUserSearch,
    handleChannelUpdate,
    channelMessageCounts,
    displayMessages,
    setDisplayMessages,
    currentUser,
    updateMemberShipsTimeToken,
    fetchMoreMemberships,
    removingChannel,
    setRemovingChannel,
    handleLeaveChannel,
    handleArchiveChannel,
    handleHomeRedirect,
  };
};

export default usePubNubData;
