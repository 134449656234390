// hooks
import { createContext } from 'react';
import usePubNubData from 'hooks/internal/pubnub/usePubNubData';

// types
import type { ReactNode, Dispatch, SetStateAction } from 'react';
import type {
  ChannelMetadataObject,
  ObjectCustom,
  UUIDMetadataObject,
} from 'pubnub';
import {
  ChatType,
  ChannelMessageType,
  User,
  MessageType,
} from 'common/types/Chat';

interface PubNubChatDataProviderProps {
  children: ReactNode;
}

export interface PubNubChatDataContextType {
  // current type of chat (job or direct)
  chatType: ChatType;
  // update chat type
  setChatType: React.Dispatch<React.SetStateAction<ChatType>>;
  // new messages from events
  newMessages: ChannelMessageType;
  // update new messages
  setNewMessages: Dispatch<SetStateAction<ChannelMessageType>>;
  // set channel creation status
  setCreatingChannel: Dispatch<SetStateAction<boolean>>;
  // channels to display
  displayChannels: ChannelMetadataObject<ObjectCustom>[];
  // current selected channel
  currentChannel?: ChannelMetadataObject<ObjectCustom>;
  // typing indicator status
  typingIndicatorNames: string[];
  // update unread messages, passed through from app level provider
  updateUnreadMessages: (channel: string, markread?: boolean) => void;
  // callback to handle user search (creating direct chat or adding coordinators in job chat)
  handleUserSearch: ({
    user,
    userChatType,
    jobId,
  }: {
    user: User;
    userChatType: ChatType;
    jobId?: string;
  }) => Promise<ChannelMetadataObject<ObjectCustom>>;
  // callback to handle channel change events
  handleChannelUpdate: (channel: ChannelMetadataObject<ObjectCustom>) => void;
  // unread messages, passed through from app level provider
  channelMessageCounts?: {
    [channel: string]: number;
  };
  // messages from current channel
  displayMessages?: MessageType[];
  // update messages for current channel
  setDisplayMessages: Dispatch<SetStateAction<MessageType[] | undefined>>;
  // current pubnub user data
  currentUser: UUIDMetadataObject<ObjectCustom>;
  // update last read time token
  updateMemberShipsTimeToken: (
    channel: string,
    timetoken?: string,
  ) => Promise<void>;
  // update channel list
  setDisplayChannels: Dispatch<
    SetStateAction<ChannelMetadataObject<ObjectCustom>[]>
  >;
  // refersh channels
  fetchMoreMemberships: () => void;
  // set/get state for when leaving channel
  removingChannel: boolean;
  setRemovingChannel: Dispatch<SetStateAction<boolean>>;
  // leave channel
  handleLeaveChannel: () => void;
  // archive channel
  handleArchiveChannel: (archive: boolean) => void;
  // handle redirect to new chat
  handleHomeRedirect: (channels: ChannelMetadataObject<ObjectCustom>[]) => void;
}

export const PubNubChatDataContext =
  createContext<PubNubChatDataContextType>(undefined);

/**
 * This provider gives chat level access to pubnub data that is shared between chat controller components.
 */
const PubNubChatDataProvider = ({ children }: PubNubChatDataProviderProps) => {
  const pubNubChatData = usePubNubData();

  return (
    <PubNubChatDataContext.Provider value={pubNubChatData}>
      {children}
    </PubNubChatDataContext.Provider>
  );
};

export default PubNubChatDataProvider;
