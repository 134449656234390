import GlobalStyle from '../components/GlobalStyle';
import { PropsWithChildren } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from 'styleguide/src/themes/theme';

export default function WrenoThemeProvider({
  children,
}: // eslint-disable-next-line @typescript-eslint/ban-types
PropsWithChildren<{}>) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
}
