import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';
import { useDispatch, useSelector, useStore } from 'react-redux';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://assets.wreno.io/',
});

/**
 * Not too sure why this is needed,
 * but we found that when doing production builds using docker,
 * that the version of react-redux imported internally by
 * @reduxjs/toolkit/query/react was not correct,
 * resulting in the queries to throw an unhandled error.
 *
 * See source code here:
 * https://github.com/reduxjs/redux-toolkit/blob/3de00f4d49e0a7372605531d47bd49f808bd18c2/packages/toolkit/src/query/react/module.ts#L17
 */
const createApi = buildCreateApi(
  coreModule(),
  reactHooksModule({
    hooks: {
      useDispatch: useDispatch,
      useSelector: useSelector,
      useStore: useStore,
    },
  }),
);

export const assets = createApi({
  baseQuery,
  endpoints: () => ({}),
  reducerPath: 'assets',
});
