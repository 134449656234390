// hooks
import { useEffect } from 'react';
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';
import useAccount from 'common/hooks/useAccount';

// types
import type { SubscribeParameters } from 'pubnub';
import useRoles from 'common/hooks/useRoles';

/**
 * Hook used to subscribe to a list to channel id's to receive events
 * The users UUID should be provided as the last item in the array.
 */
const usePubNubSubscribe = (
  options: SubscribeParameters = {},
): (() => void) => {
  const pubnub = usePubNub();
  const { currentUserId } = useAccount();
  const { isCoordinator } = useRoles();

  useEffect(() => {
    if (!pubnub || !options?.channels) return;
    const currentSubscriptions = pubnub?.getSubscribedChannels();

    const subscribeChannels = options?.channels?.filter(
      (channel) => !currentSubscriptions?.includes(channel),
    );
    const unsubscribeChannels = currentSubscriptions?.filter(
      (channel) => !options?.channels?.includes(channel),
    );

    // add subscriptions
    if (subscribeChannels?.length) {
      pubnub?.subscribe({
        channels: subscribeChannels.filter((id) =>
          isCoordinator ? Boolean(id) : id?.includes(currentUserId),
        ),
        withPresence: options?.withPresence || false,
      });
    }

    // removed subscriptions that are not required
    if (unsubscribeChannels?.length) {
      pubnub?.unsubscribe({
        channels: unsubscribeChannels,
      });
    }
  }, [options, pubnub, currentUserId, isCoordinator]); // eslint-disable-line react-hooks/exhaustive-deps

  return () => {
    pubnub?.unsubscribe(options);
  };
};

export default usePubNubSubscribe;
