import { useCallback, useEffect, useRef, useState } from 'react';
import canUseDOM from 'common/utils/canUseDOM';
import { getTimeDiffInDays } from 'utils/helpers/getDateTime';
import { getSuppressedNotificationTime } from 'utils/helpers/localStorageHelpers';

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: Array<string>;
  readonly userChoice: Promise<{
    outcome: 'accepted' | 'dismissed';
    platform: string;
  }>;
  prompt(): Promise<void>;
}

const checkInstalled = () => {
  let displayMode = 'browser tab';
  if (canUseDOM && window.matchMedia('(display-mode: standalone)').matches) {
    displayMode = 'standalone';
  }
  return displayMode === 'standalone';
};

const useInstallPWA = () => {
  const [isInstalled, setInstalled] = useState(checkInstalled());
  const [readyToInstall, setReadyToInstall] = useState(false);
  const deferredPrompt = useRef<BeforeInstallPromptEvent>();

  const installAppReminderDate = getSuppressedNotificationTime('installApp');

  const diffDays = getTimeDiffInDays(installAppReminderDate);

  useEffect(() => {
    const listener = () => {
      setInstalled(checkInstalled());
    };
    const media = window.matchMedia('(display-mode: standalone)');
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  });

  const onRequestInstall = useCallback(() => {
    if (deferredPrompt.current && canUseDOM) {
      deferredPrompt.current.prompt();
    }
  }, [deferredPrompt]);

  useEffect(() => {
    if (canUseDOM) {
      const handleInstallPrompt = (e: Event) => {
        deferredPrompt.current = e as BeforeInstallPromptEvent;
        setReadyToInstall(true);
      };
      if (!deferredPrompt.current) {
        window.addEventListener('beforeinstallprompt', handleInstallPrompt);
      }

      return () => {
        window.removeEventListener('beforeinstallprompt', handleInstallPrompt);
      };
    }
  }, [setReadyToInstall]);

  return {
    isInstalled,
    readyToInstall:
      (!isInstalled && readyToInstall && !installAppReminderDate) ||
      (!isInstalled && readyToInstall && diffDays > 7),
    onRequestInstall,
  };
};

export default useInstallPWA;
