import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Graphik', sans-serif;
  }
  body {
    margin: 0;
  }
  .firebase-emulator-warning {
    display: none;
  }
`;

export default GlobalStyle;
