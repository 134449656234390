// hooks
import { useState, useCallback } from 'react';

// types
import type {
  MessageEvent,
  FileEvent,
  ChannelMetadataObject,
  ObjectCustom,
} from 'pubnub';
import { ChannelMessageType } from 'common/types/Chat';
import { getMessageContentAndDate } from 'utils/pubnubUtils';

interface ListenerOptions {
  // current display channels
  displayChannels: ChannelMetadataObject<ObjectCustom>[];
  setDisplayChannels: (channels: ChannelMetadataObject<ObjectCustom>[]) => void;
}
interface MessageEventMeta extends MessageEvent {
  userMetadata?: object;
}

/**
 * Internal hook used as an extension of the usePubNubListener hooke to handle incoming
 * message and file events to display in the chat area.
 */
const usePubNubMessageFileEvent = ({
  displayChannels,
  setDisplayChannels,
}: ListenerOptions) => {
  // state
  // get/set new messages
  const [newMessages, setNewMessages] = useState<ChannelMessageType>({});

  // Functions
  // handle text base message events. adds incoming messages to be displayed in channel.
  // updates last message and last message time token to sort messages and unread count.
  const handleMessage = useCallback(
    async (message: MessageEventMeta) => {
      try {
        const _messages = newMessages ? { ...newMessages } : {};
        if (!_messages[message.channel]) {
          _messages[message.channel] = [];
        }
        const _message = {
          ...message,
          ...{ uuid: message.publisher, meta: message.userMetadata },
        };
        _messages[message.channel].push(_message);
        const _displayChannels = [...displayChannels];
        const channel = _displayChannels?.find(
          (dChannel) => dChannel.id === message.channel,
        );
        const messageTimeData = getMessageContentAndDate(_message);
        if (channel) {
          channel.custom = {
            ...(channel.custom || {}),
            lastMessage: messageTimeData.message,
            lastMessageTime: messageTimeData.timetoken,
          };
          setDisplayChannels(_displayChannels);
        }
        setNewMessages(_messages);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [newMessages, displayChannels, setDisplayChannels],
  );

  // handle non-text base message events. adds incoming messages to be displayed in channel.
  // updates last message and last message time token to sort messagesand unread count.
  const handleFile = useCallback(
    async (fileData: FileEvent) => {
      try {
        const { file, message } = fileData;
        const messageObject = {
          ...fileData,
          message: { file, message },
          messageType: 4,
          uuid: fileData.publisher,
        };
        const _messages = newMessages ? { ...newMessages } : {};
        if (!_messages[messageObject.channel]) {
          _messages[messageObject.channel] = [];
        }

        _messages[messageObject.channel].push(messageObject);
        const _displayChannels = [...displayChannels];
        const channel = _displayChannels?.find(
          (dChannel) => dChannel.id === message.channel,
        );
        if (channel) {
          channel.custom = {
            ...(channel.custom || {}),
            lastMessage: messageObject.file?.name,
            lastMessageTime: fileData.timetoken,
          };
          setDisplayChannels(_displayChannels);
        }
        setNewMessages(_messages);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
    [newMessages, setNewMessages, displayChannels, setDisplayChannels],
  );

  return {
    newMessages,
    setNewMessages,
    handleFile,
    handleMessage,
  };
};

export default usePubNubMessageFileEvent;
