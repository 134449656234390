import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Graphik', sans-serif;
  }
  body {
    margin: 0;
  }
  .firebase-emulator-warning {
    display: none;
  }
  @font-face {
    font-family: 'Graphik';
    src: url('https://assets.wreno.io/fonts/graphik/Graphik-Regular-Web.woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Graphik';
    src: url('https://assets.wreno.io/fonts/graphik/Graphik-Medium-Web.woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Graphik';
    src: url('https://assets.wreno.io/fonts/graphik/Graphik-Bold-Web.woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
`;

export default GlobalStyle;
