import { AccountRole } from 'common/types/AccountRole';
import type { ChatType } from 'common/types/Chat';

export const COORDINATOR_ROLES: AccountRole[] = [
  AccountRole.ADMIN,
  AccountRole.COORDINATOR,
];

export const CHAT_TYPES = {
  DIRECT: 'direct' as ChatType,
  JOB: 'job' as ChatType,
} as const;
