import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type NotificationState = {
  unreadNotifications: boolean;
  pushToken: string | null;
};

type RootState = {
  notifications: NotificationState;
};

const initialState: NotificationState = {
  unreadNotifications: false,
  pushToken: null,
};

export const notificationSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUnreadNotifications(state, { payload }: PayloadAction<boolean>) {
      state.unreadNotifications = payload;
    },
    setPushToken(state, { payload }: PayloadAction<string | null>) {
      state.pushToken = payload;
    },
  },
});

export const { setUnreadNotifications, setPushToken } =
  notificationSlice.actions;
export default notificationSlice.reducer;

export const selectReadNotificationStatus = (state: RootState): boolean =>
  state.notifications.unreadNotifications;
