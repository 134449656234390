//types
import type { ChannelMetadataObject, ObjectCustom } from 'pubnub';
import { ChatType } from 'common/types/Chat';

//hooks
import { useState, useCallback, useEffect } from 'react';
import { usePubNub } from 'context/PubNubGlobalInstanceProvider';
import { useRouter } from 'next/router';
import usePubNubAppData from 'hooks/pubnub/usePubNubAppData';
import { useCreateChatMutation } from 'services/endpoints/chat';
import { getMessageContentAndDate } from 'utils/pubnubUtils';
import { CHAT_TYPES } from 'constants/constants';

interface HookParams {
  chatType: ChatType;
  setChatType: (newChatType: ChatType) => void;
}

/**
 * Internal hook used by the usePubNubData hook to provide data for pubnub channels. This includes get/set
 * the current channels, creating new channels.
 *
 * Also gets data from app level context and passes to the chat level provider.
 */
const usePubNubChannels = ({ chatType, setChatType }: HookParams) => {
  const [createChatFn] = useCreateChatMutation();
  // state
  // get/set channels to display
  const [displayChannels, setDisplayChannels] = useState<
    ChannelMetadataObject<ObjectCustom>[]
  >([]);
  // set creating channel state
  const [creatingChannel, setCreatingChannel] = useState(false);
  const [removingChannel, setRemovingChannel] = useState(false);
  const [archivingChannel, setArchivingChannel] = useState(false);

  // set current channel
  const [currentChannel, setCurrentChannel] =
    useState<ChannelMetadataObject<ObjectCustom>>();

  // hook data
  const pubnub = usePubNub();
  const router = useRouter();

  // get data from app level context. this will be passed to chat level provider
  const {
    channelMessageCounts,
    fetchMoreMemberships,
    updateUnreadMessages,
    joinedChannels,
    membershipDataLoading,
  } = usePubNubAppData();

  // functions
  // update the current channel and set correct url path for channel
  const handleChannelUpdate = useCallback(
    (channel: ChannelMetadataObject<ObjectCustom>) => {
      if (!channel) return;
      setCurrentChannel(channel);

      const channelId = channel.id?.replace(`.${pubnub?.getUUID()}`, '');
      const idParts = channelId.split('.');
      const cType = idParts[0] as ChatType;
      const cId =
        idParts.length > 2 ? `${idParts[1]}-${idParts[2]}` : idParts[1];

      if (cType !== chatType) {
        setChatType(cType);
      }
      router.push(`/chat/${cType}/${cId}`);
    },
    [pubnub, router, chatType, setChatType],
  );

  // creates a new chat and add current user as only member. additional users will be
  // added when message is sent
  const createChat = useCallback(
    async ({
      userId,
      createChatType,
      jobId,
    }: {
      userId: string;
      createChatType: ChatType;
      jobId?: string;
    }) => {
      let createdChannel: ChannelMetadataObject<ObjectCustom>;
      try {
        if (!pubnub) throw new Error('No Pubnub client found to create chat');
        if (creatingChannel) return;
        setCreatingChannel(true);

        createdChannel = await createChatFn({
          users: [userId, pubnub?.getUUID()],
          jobId: jobId ? Number(jobId) : undefined,
        }).unwrap();

        let lastMessage: string;
        let lastMessageTime: string;

        const lastMessageChannels =
          createdChannel && !createdChannel?.custom?.lastMessage
            ? (
                await pubnub.fetchMessages({
                  channels: [createdChannel?.id],
                  count: 1,
                })
              )?.channels
            : undefined;
        if (
          lastMessageChannels &&
          lastMessageChannels[createdChannel?.id]?.length
        ) {
          const messageAndTime = getMessageContentAndDate(
            lastMessageChannels[createdChannel?.id][0],
          );
          lastMessage = messageAndTime?.message;
          lastMessageTime = messageAndTime?.timetoken;
        }
        setDisplayChannels([
          ...(displayChannels || []),
          ...(createdChannel
            ? [
                {
                  ...createdChannel,
                  custom: {
                    ...(createdChannel.custom || {}),
                    lastMessage,
                    lastMessageTime,
                  },
                },
              ]
            : []),
        ]);
        setCreatingChannel(false);
        handleChannelUpdate(createdChannel);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Error creating ${createChatType} chat, with user ${userId}, ${
            jobId ? `and job ID: ${jobId}` : ''
          }`,
          error.status || error,
        );
        setCreatingChannel(false);
      }

      return createdChannel;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pubnub, creatingChannel, displayChannels, handleChannelUpdate],
  );

  const handleLeaveChannel = useCallback(async () => {
    if (!currentChannel) return;
    try {
      setRemovingChannel(true);
      const response = await pubnub?.objects?.removeMemberships({
        channels: [currentChannel?.id],
      });

      if (response?.data) {
        fetchMoreMemberships();
      }
      setDisplayChannels((currentChannels) => {
        if (currentChannels) {
          return currentChannels.filter(
            (channel) => channel.id !== currentChannel?.id,
          );
        }
      });
      setCurrentChannel(undefined);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(
        `Error leaving pubnub channel $currentChannelId}: `,
        error.status || error,
      );
      setRemovingChannel(false);
    }
  }, [
    pubnub,
    currentChannel,
    setDisplayChannels,
    setRemovingChannel,
    fetchMoreMemberships,
  ]);

  const handleArchiveChannel = useCallback(
    async (archive: boolean) => {
      if (!currentChannel) return;
      try {
        setArchivingChannel(true);
        const metadata = archive
          ? {
              channel: currentChannel?.id,
              data: {
                custom: {
                  ...(currentChannel.custom || {}),
                  status: 'ARCHIVED',
                },
              },
            }
          : {
              channel: currentChannel?.id,
              data: {
                custom: {
                  ...(currentChannel.custom || {}),
                  status: 'ACTIVE',
                },
              },
            };
        await pubnub?.objects?.setChannelMetadata(metadata);

        setCurrentChannel(undefined);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(
          `Error leaving pubnub channel $currentChannelId}: `,
          error.status || error,
        );
        setArchivingChannel(false);
      }
    },
    [pubnub, currentChannel, setArchivingChannel],
  );

  useEffect(() => {
    if (membershipDataLoading || !currentChannel) return;
    const isJobChat = currentChannel?.id?.startsWith(CHAT_TYPES.JOB);
    if (isJobChat && chatType !== CHAT_TYPES.JOB) {
      setChatType(CHAT_TYPES.JOB);
    } else if (!isJobChat && chatType === CHAT_TYPES.JOB) {
      setChatType(CHAT_TYPES.DIRECT);
    }
  }, [currentChannel, membershipDataLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    displayChannels,
    setDisplayChannels,
    joinedChannels,
    fetchMoreMemberships,
    createChat,
    joinedChannelLoading: membershipDataLoading,
    currentChannel,
    setCurrentChannel,
    handleChannelUpdate,
    creatingChannel,
    setCreatingChannel,
    channelMessageCounts,
    updateUnreadMessages,
    removingChannel,
    setRemovingChannel,
    handleLeaveChannel,
    archivingChannel,
    setArchivingChannel,
    handleArchiveChannel,
  };
};

export default usePubNubChannels;
