export enum WorkOrderStatus {
  DRAFT = 'DRAFT',
  UNCLAIMED = 'UNCLAIMED',
  ACCEPTED = 'ACCEPTED',
  UNACCEPTED = 'UNACCEPTED',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REWORK = 'REWORK',
  TRIP_FEE_ONLY = 'TRIP_FEE_ONLY',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
  CANT_SERVICE = 'CANT_SERVICE',
}
