import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Company } from 'common/types/Company';

export type CompanyState = Omit<Company, 'createdAt' | 'updatedAt'>;

type RootState = {
  company: Partial<CompanyState>;
};

const initialState: Partial<CompanyState> = {
  id: undefined,
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setCompanyData(
      state,
      { payload }: PayloadAction<Partial<CompanyState> | undefined>,
    ) {
      state.id = payload?.id;
    },
    removeCompanyData() {
      return initialState;
    },
  },
});

export const { setCompanyData, removeCompanyData } = companySlice.actions;
export default companySlice.reducer;

export const selectCompanyId = (state: RootState): number | undefined =>
  state?.company?.id;

export const selectCompany = (state: RootState): Partial<CompanyState> =>
  state?.company;
