import { motion } from 'framer-motion';
import CloseIcon from '../../components/icons/CloseIcon';
import Typography from '../../components/Typography';
import {
  ReminderPopUpContainer,
  ReminderPopUpHeader,
  ReminderPopUpContent,
  ReminderPopUpFooter,
  ReminderButton,
} from './ReminderPopup.styles';

export interface Props {
  onClose: () => void;
  onLater?: () => void;
  onSubmit: () => void;
  btnLabel1?: string;
  btnLabel2: string;
  title: string;
  content: string;
}

const ReminderPopUp = ({
  onClose,
  onLater,
  onSubmit,
  btnLabel1 = 'Later',
  btnLabel2,
  title,
  content,
}: Props) => {
  return (
    <motion.div
      initial={{ x: 300, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      exit={{ x: 300, opacity: 0 }}
    >
      <ReminderPopUpContainer>
        <ReminderPopUpHeader>
          <Typography variant="heading5">{title}</Typography>
          <CloseIcon onClick={onClose} cursor="pointer" />
        </ReminderPopUpHeader>
        <ReminderPopUpContent>
          <Typography variant="bodyStandard">{content}</Typography>
        </ReminderPopUpContent>
        <ReminderPopUpFooter>
          <ReminderButton
            variant="secondary"
            onClick={() => {
              onLater && onLater();
              onClose();
            }}
          >
            {btnLabel1}
          </ReminderButton>
          <ReminderButton onClick={onSubmit}>{btnLabel2}</ReminderButton>
        </ReminderPopUpFooter>
      </ReminderPopUpContainer>
    </motion.div>
  );
};

export default ReminderPopUp;
