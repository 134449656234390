import { SVGAttributes } from 'react';

export enum NotificationTag {
  CLOCK_IN_MISSED = 'CLOCK_IN_MISSED',
  CLOCK_IN_REMINDER = 'CLOCK_IN_REMINDER',
  UPCOMING_SHIFT_REMINDER = 'UPCOMING_SHIFT_REMINDER',
  POSTED_SHIFT_NOT_CLAIMED = 'POSTED_SHIFT_NOT_CLAIMED',
  JOB_AVAILABLE_FOR_CLAIM = 'JOB_AVAILABLE_FOR_CLAIM',
  JOB_FORM_SUBMITTED = 'JOB_FORM_SUBMITTED',
  JOB_REQUEST_ACCEPT = 'JOB_REQUEST_ACCEPT',
  JOB_UPDATE_APPROVED = 'JOB_UPDATE_APPROVED',
  JOB_UPDATE_REJECTED = 'JOB_UPDATE_REJECTED',
  WORKER_CLAIMED_SHIFT = 'WORKER_CLAIMED_SHIFT',
  JOB_CHAT = 'JOB_CHAT',
  DIRECT_CHAT = 'DIRECT_CHAT',
}

export type Notification = {
  id: string;
  tag: NotificationTag;
  data?: Record<string, string>;
  title: string;
  body: string;
  userId: string;
  createdAt: {
    _seconds: number;
    _nanoseconds: number;
  };
};

export type NotificationIconComponent = {
  bgColor?: string;
  color: string;
  icon: (props: SVGAttributes<SVGElement>) => React.ReactNode;
};
