import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

export type AuthState = {
  authToken: string | null;
  initialized: boolean;
};

export const authInitialState: AuthState = {
  authToken: null,
  initialized: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setAuthToken(state, action: PayloadAction<string | null>) {
      state.initialized = true;
      state.authToken = action.payload;
    },
    setInitialized(state, action: PayloadAction<boolean>) {
      state.initialized = action.payload;
    },
  },
});

export const { setAuthToken, setInitialized } = authSlice.actions;
export default authSlice.reducer;
