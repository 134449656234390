// hooks
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'hooks/store';

// types
import PubNub from 'pubnub';
import { useGetChatTokenQuery } from 'services/endpoints/auth';
import useAccount from 'common/hooks/useAccount';

interface HookOptions {
  skipTimer?: boolean;
}

/**
 * Hook used to handle the pubnub client instance and chat token logic. To make queries to pubnub API an instance with
 * user ID (comes from firebase) and pubnub chat token (comes from api backend server) are required.
 */
const usePubNubClient = (
  options?: HookOptions,
): { pubnub: PubNub; loading: boolean } => {
  // instance ready
  const [pubnubInstantiated, setPubnubInstantiated] = useState(false);
  const { skipTimer } = options || {};
  // auth user to get uuid from
  const { currentUserId: uuid, isAuthenticated } = useAccount();

  const dispatch = useAppDispatch();
  const [pubnub, setPubNub] = useState<PubNub>(null);

  const { data, isLoading, isFetching, error, refetch } = useGetChatTokenQuery(
    undefined,
    {
      skip: !isAuthenticated,
    },
  );

  // pubnub instance
  useEffect(() => {
    if (uuid) {
      setPubNub(
        new PubNub({
          publishKey: process.env.NEXT_PUBLIC_PUB_NUB_PUBLISH_KEY,
          subscribeKey: process.env.NEXT_PUBLIC_PUB_NUB_SUBSCRIBE_KEY,
          userId: uuid,
        }),
      );
    } else {
      if (pubnub) {
        pubnub.stop();
      }
      setPubNub(null);
      setPubnubInstantiated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid]);

  // fetch the token when logged in and start timer to refetch token based on TTL
  useEffect(() => {
    let timeout: NodeJS.Timeout = null;
    if (isFetching || !pubnub || !data?.chatToken) {
      return;
    }
    const tokenData = pubnub.parseToken(data?.chatToken);
    // time to live set in token, in minutes
    const ttl = tokenData.ttl || 0;
    const timeoutDuration = Math.round(0.9 * ttl * 60 * 1000);

    pubnub?.setToken(data?.chatToken);
    setPubnubInstantiated(true);

    timeout =
      ttl && !skipTimer
        ? setTimeout(() => {
            refetch();
          }, timeoutDuration)
        : null;

    return () => clearTimeout(timeout);
  }, [
    dispatch,
    pubnub,
    uuid,
    data,
    skipTimer,
    refetch,
    data?.chatToken,
    isFetching,
    isAuthenticated,
    error,
  ]);

  return {
    pubnub: pubnubInstantiated ? pubnub : undefined,
    loading: isLoading || isFetching || !pubnubInstantiated,
  };
};

export default usePubNubClient;
