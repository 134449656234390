import styled from 'styled-components';
import Button from '../../components/Button';

export const ReminderPopUpContainer = styled.div`
  width: 277px;
  margin-bottom: 24px;
  padding: 20px 24px;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(19, 32, 19, 0.15);
  border-radius: 10px;
`;

export const ReminderPopUpHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ReminderPopUpContent = styled.div`
  margin-top: 7px;
`;

export const ReminderPopUpFooter = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

export const ReminderButton = styled(Button)`
  flex: 1 1 0px;
`;
