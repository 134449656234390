// hooks
import { createContext } from 'react';
import usePubNubMembershipData from 'hooks/internal/pubnub/usePubNubMembershipData';
import useAccount from 'common/hooks/useAccount';

// types
import type { ReactNode } from 'react';
import type { ChannelMetadataObject, ObjectCustom } from 'pubnub';

interface PubNubAppProviderProps {
  children: ReactNode;
}

export interface PubNubAppContextType {
  // unread counts for chat channels
  channelMessageCounts?: {
    [channel: string]: number;
  };
  // fetch more memberships on event
  fetchMoreMemberships: () => void;
  // update unread messages state
  updateUnreadMessages: (channel: string, markread?: boolean) => void;
  // currently joined channels
  joinedChannels: ChannelMetadataObject<ObjectCustom>[];
  // loading state
  membershipDataLoading: boolean;
  // Chat tokens for push notitifications
  chatPushTokens?: string[];
}

export const PubNubAppContext = createContext<PubNubAppContextType>(undefined);

/**
 * This provider gives app level access to pubnub user channel data and unread message counts.
 * This will allow the data to be used to enable site wide chat notifications and unread counts.
 */
const PubNubAppProvider = ({ children }: PubNubAppProviderProps) => {
  const {
    channelMessageCounts,
    fetchMoreMemberships,
    updateUnreadMessages,
    joinedChannels,
    membershipDataLoading,
  } = usePubNubMembershipData();

  const { user } = useAccount();
  const chatPushTokens = user?.pushTokens;

  return (
    <PubNubAppContext.Provider
      value={{
        channelMessageCounts,
        fetchMoreMemberships,
        updateUnreadMessages,
        joinedChannels,
        membershipDataLoading,
        chatPushTokens,
      }}
    >
      {children}
    </PubNubAppContext.Provider>
  );
};

export default PubNubAppProvider;
