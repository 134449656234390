import type { Account } from 'common/types/Account';
import { type AccountLoginResponse } from 'common/types/Responses/Auth';
import type {
  AccountCreateRequest,
  AccountLoginRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  UpdateAccountRequest,
  UpdatePasswordRequest,
  UpdatePasswordResponse,
  VerifyEmailCodeRequest,
} from 'common/types/Requests/Auth';
import { api } from '../../store/api';
import {
  loginWithCustomIdToken,
  resetPassword as firebaseResetPassword,
} from 'common/utils/firebase';
import { setAuthToken, setInitialized } from '../../store/slices/authSlice';

const AUTH = 'auth';

const apiWithTag = api.enhanceEndpoints({
  addTagTypes: ['Auth', 'Companies', 'Opportunity', 'Opportunities'],
});

export const authEndpoints = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    me: builder.query<Account, void>({
      query: () => ({ url: `${AUTH}/me` }),
      providesTags: ['Auth'],
    }),
    login: builder.mutation<AccountLoginResponse, AccountLoginRequest>({
      query: ({ appType, ...rest }) => ({
        method: 'POST',
        url: `${AUTH}/login`,
        body: {
          ...rest,
          appType,
        },
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          dispatch(setInitialized(false));
          const { data } = await queryFulfilled;
          const loginRes = await loginWithCustomIdToken(data.authToken);
          const token = await loginRes.user.getIdToken();
          dispatch(setAuthToken(token));
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to login', err);
          dispatch(setAuthToken(null));
        }
      },
      invalidatesTags: ['Auth'],
    }),
    createAccount: builder.mutation<AccountLoginResponse, AccountCreateRequest>(
      {
        query: (payload) => ({
          method: 'POST',
          url: `${AUTH}/create-account`,
          body: payload,
        }),
        async onQueryStarted(_data, { dispatch, queryFulfilled }) {
          try {
            dispatch(setInitialized(false));
            const { data } = await queryFulfilled;
            const loginRes = await loginWithCustomIdToken(data.authToken);
            const token = await loginRes.user.getIdToken();
            dispatch(setAuthToken(token));
          } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Failed to create account', err);
            dispatch(setAuthToken(null));
          }
        },
        invalidatesTags: ['Auth'],
      },
    ),
    forgotPassword: builder.mutation({
      query: ({ appType, ...rest }: ForgotPasswordRequest) => ({
        url: `${AUTH}/forgot-password`,
        method: 'POST',
        body: {
          ...rest,
          appType,
        },
      }),
    }),
    resetPassword: builder.mutation<null, ResetPasswordRequest>({
      queryFn: async (body) => {
        await firebaseResetPassword(body.oobCode, body.newPassword);
        return { data: null };
      },
    }),
    updateAccount: builder.mutation<
      { user: Account; authToken: string },
      UpdateAccountRequest
    >({
      query: (body) => ({
        method: 'POST',
        url: `${AUTH}/update`,
        body,
      }),
      invalidatesTags: ['Companies', 'Opportunity', 'Opportunities'],
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data?.authToken) {
            const loginRes = await loginWithCustomIdToken(data.authToken);
            const token = await loginRes.user.getIdToken();
            dispatch(setAuthToken(token));
          }
          if (data?.user) {
            dispatch(
              authEndpoints.util.updateQueryData('me', undefined, (draft) => {
                Object.assign(draft, {
                  ...data.user,
                });
              }),
            );
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to login', err);
        }
      },
    }),
    updatePassword: builder.mutation<
      UpdatePasswordResponse,
      UpdatePasswordRequest
    >({
      query: (body) => ({
        method: 'POST',
        url: `${AUTH}/update-password`,
        body,
      }),
      async onQueryStarted(_data, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.login && data.user) {
            const loginRes = await loginWithCustomIdToken(
              data.login?.authToken,
            );
            const token = await loginRes.user.getIdToken();
            dispatch(setAuthToken(token));
            dispatch(
              authEndpoints.util.updateQueryData('me', undefined, (draft) => {
                Object.assign(draft, {
                  ...data?.user,
                  requirePasswordReset: false,
                });
              }),
            );
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error('Failed to login', err);
        }
      },
    }),
    sendEmailVerification: builder.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: `${AUTH}/send-email-verification`,
      }),
    }),
    verifyEmail: builder.mutation<void, VerifyEmailCodeRequest>({
      query: (body) => ({
        method: 'POST',
        url: `${AUTH}/verify-email`,
        body,
      }),
      invalidatesTags: ['Auth'],
    }),
  }),
  overrideExisting: true,
});

export const {
  useMeQuery,
  useLoginMutation,
  useCreateAccountMutation,
  useForgotPasswordMutation,
  useResetPasswordMutation,
  useUpdateAccountMutation,
  useUpdatePasswordMutation,
  useSendEmailVerificationMutation,
  useVerifyEmailMutation,
} = authEndpoints;
