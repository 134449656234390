import { useCallback, useEffect, useRef, useState } from 'react';
import canUseDOM from 'common/utils/canUseDOM';
import { sendNotification as _sendNotification } from 'utils/browserNotifications';
import useAccount from 'common/hooks/useAccount';
import { getSuppressedNotificationTime } from 'utils/helpers/localStorageHelpers';
import { getTimeDiffInDays } from 'utils/helpers/getDateTime';

const useBrowserNotifications = () => {
  const currentNotification = useRef<Notification>();
  const supportsNotifications = canUseDOM && 'Notification' in window;
  const isNotificationsEnabled =
    supportsNotifications && Notification?.permission === 'granted';
  const [permissionGranted, setPermissionGranted] = useState(false);
  const { isAuthenticated } = useAccount();

  const notificationReminderDate =
    getSuppressedNotificationTime('notification');

  const diffDays = getTimeDiffInDays(notificationReminderDate);

  const requestNotificationPermissions = async () => {
    try {
      if (
        canUseDOM &&
        'Notification' in window &&
        Notification?.permission !== 'granted' &&
        Notification?.permission !== 'denied'
      ) {
        const permission = await Notification?.requestPermission();
        if (permission === 'granted') {
          setPermissionGranted(true);
        }
      }
    } catch (error) {
      //eslint-disable-next-line
      console.error('Error getting notification permissions:', error);
    }
  };

  const sendNotification = useCallback(
    ({
      title,
      options,
      onClick,
    }: {
      title: string;
      options: NotificationOptions;
      onClick?: (e: Event) => void;
    }) => {
      if (currentNotification.current) {
        if (currentNotification.current.tag === options.tag) {
          return;
        }
        currentNotification.current.close();
      }
      const notification = _sendNotification({
        title,
        options,
        onClick,
      });

      currentNotification.current = notification;
    },
    [currentNotification],
  );

  useEffect(() => {
    if (permissionGranted && isAuthenticated && canUseDOM) {
      window.location.reload();
    }
  }, [permissionGranted, isAuthenticated]);

  return {
    requestNotificationPermissions,
    sendNotification,
    notificationPermissionsGranted:
      canUseDOM && 'Notification' in window
        ? Notification?.permission === 'granted'
        : false,
    canAskToEnableNotifications:
      isAuthenticated &&
      supportsNotifications &&
      !isNotificationsEnabled &&
      Notification?.permission !== 'denied' &&
      (!notificationReminderDate || diffDays > 7),
    isNotificationsEnabled: isNotificationsEnabled,
  };
};

export default useBrowserNotifications;
